import { Link } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import { Button, Typography } from "@mui/material";

const NotFound404Page: React.FC = () => {


  return (
    <body>    
        <PageTitle title={"404 NOT FOUND"}/>
        <div className="boxed_wrapper" style={{display: "flex", flexDirection: "column", alignItems: "center", paddingLeft: "10vw", paddingRight: "10vw", height: "80vh"}}>
            <br/>
            <Typography variant="h4" sx={{textAlign: "center" }}>
              Opss Looks Like This URL Is Not Found
            </Typography>
            <br/>
            <Link to="/" style={{ textDecoration: 'none' }}>
                <Button variant="contained" color="primary">
                    Go Back To Home Page
                </Button>
            </Link>
        </div>    
    </body>
  );
};

export default NotFound404Page;
