import { useEffect, useState } from "react";

import { GetUserInvoices } from "../services/GetUserInvoices";

export const useGetUserInvoices = (userId:string) => {
  const [res, setRes] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const fetchInvoices = async () => {
      await getInvoices(userId);
    };
    fetchInvoices();
  }, []);

  const getInvoices = async (customerId: string) => {
    try {
      setLoading(true);

      const apiResponse = await GetUserInvoices( customerId);

      if (apiResponse?.Success === 0) {
        setError(apiResponse.Message);
      } else {
        setRes(apiResponse.Message);
        const invoices = apiResponse?.Data
        setInvoices(invoices);
        // console.log(apiResponse?.Data);
    }
} catch (error: any) {
    setError("Error getting data");
} finally {
        setLoading(false);
    }
  };

  return { getInvoices, res, loading, error, invoices};
};
