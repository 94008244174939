import { useState } from "react";

import { useDispatch } from "react-redux";
import { ForgotPassword } from "../services/ForgotPassword";

export const useForgotPassword = () => {
  const [res, setRes] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useDispatch();

  const forgotPassword = async (body: any) => {
    try {
      setLoading(true);

      
      const apiResponse = await ForgotPassword(body);

      if (apiResponse?.Success === 0) {
        setError(apiResponse.Message);
      } else {
        setRes(apiResponse.Message);
      }
    } catch (error: any) {
      setError("Error Sending Mail");
    } finally {
      setLoading(false);
    }
  };

  return { forgotPassword, res, loading, error };
};
