import { useState } from "react";
import { ContactUs } from "../services/ContactUs";
export const useContactUs = () => {
  const [res, setRes] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const contactUs = async (name:string, email:string, message:string) => {
    try {
      setLoading(true);

      const apiResponse = await ContactUs(name, email,message);
      if (apiResponse?.Success === 0) {
        setError(apiResponse.Message);
      } else {
        setRes(apiResponse.Message);
    }
} catch (error: any) {
    setError("Error sending message");
} finally {
        setLoading(false);
    }
  };

  return { contactUs, res, loading, error};
};
