// apiService.ts
import { AxiosRequestConfig } from 'axios';
import axios from '../helpers/axiosInterceptor'
import { Endpoints } from '../constants/Endpoints';

export const getCoupons = async () => {
  
  try {
    const config: AxiosRequestConfig = {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
    
    const response:any = await axios.get(Endpoints.GetCoupons, config);
    
        // Check for unauthorized error
        if (response.data.Success === 0 && response.data.ErrorCode === 1) {
          // Handle unauthorized error, for example, redirect to login page
          console.error('Un expected error.');
          // You can redirect the user to the login page or show a login modal
          // For example, uncomment the line below to redirect to the login page
          // window.location.href = '/login';
          throw new Error('Un expected error');
        }
    
      return response.data;
    
    
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
