import { useGetCoupons } from "../../hooks/useGetCoupons";
import Loader from "../UI/loader/Loader";
import ReactHtmlParser from 'react-html-parser';
// const couponss = [
//   {
//     "ODataEtag": null,
//     "SystemId": "57247f8e-f7d0-ee11-9078-0022482c3ec0",
//     "Code": "12",
//     "Description": "aaaa",
//     "MarketingText":  "<div style=\"font-family: &quot;segoe ui&quot;, &quot;segoe wp&quot;, segoe, device-segoe, tahoma, helvetica, arial, sans-serif; font-size: 10.5pt; color: rgba(33, 33, 33, 1)\"><span style=\"background-color: rgba(255, 255, 128, 1)\"><b>Test Rich Text</b></span><b>. dsadas</b></div>"
//   },{
//     "ODataEtag": null,
//     "SystemId": "57247f8e-f7d0-ee11-9078-0022482c3ec0",
//     "Code": "12",
//     "Description": "aaaa",
//     "MarketingText":  "<div style=\"font-family: &quot;segoe ui&quot;, &quot;segoe wp&quot;, segoe, device-segoe, tahoma, helvetica, arial, sans-serif; font-size: 10.5pt; color: rgba(33, 33, 33, 1)\"><span style=\"background-color: rgba(255, 255, 128, 1)\"><b>Test Rich Text</b></span><b>. dsadas</b></div>"
//   },{
//     "ODataEtag": null,
//     "SystemId": "57247f8e-f7d0-ee11-9078-0022482c3ec0",
//     "Code": "12",
//     "Description": "aaaa",
//     "MarketingText":  "<div style=\"font-family: &quot;segoe ui&quot;, &quot;segoe wp&quot;, segoe, device-segoe, tahoma, helvetica, arial, sans-serif; font-size: 10.5pt; color: rgba(33, 33, 33, 1)\"><span style=\"background-color: rgba(255, 255, 128, 1)\"><b>Test Rich Text</b></span><b>. dsadas</b></div>"
//   },{
//     "ODataEtag": null,
//     "SystemId": "57247f8e-f7d0-ee11-9078-0022482c3ec0",
//     "Code": "12",
//     "Description": "aaaa",
//     "MarketingText":  "<div style=\"font-family: &quot;segoe ui&quot;, &quot;segoe wp&quot;, segoe, device-segoe, tahoma, helvetica, arial, sans-serif; font-size: 10.5pt; color: rgba(33, 33, 33, 1)\"><span style=\"background-color: rgba(255, 255, 128, 1)\"><b>Test Rich Text</b></span><b>. dsadas</b></div>"
//   },{
//     "ODataEtag": null,
//     "SystemId": "57247f8e-f7d0-ee11-9078-0022482c3ec0",
//     "Code": "12",
//     "Description": "aaaa",
//     "MarketingText":  "<div style=\"font-family: &quot;segoe ui&quot;, &quot;segoe wp&quot;, segoe, device-segoe, tahoma, helvetica, arial, sans-serif; font-size: 10.5pt; color: rgba(33, 33, 33, 1)\"><span style=\"background-color: rgba(255, 255, 128, 1)\"><b>Test Rich Text</b></span><b>. dsadas</b></div>"
//   },{
//     "ODataEtag": null,
//     "SystemId": "57247f8e-f7d0-ee11-9078-0022482c3ec0",
//     "Code": "12",
//     "Description": "aaaa",
//     "MarketingText":  "<div style=\"font-family: &quot;segoe ui&quot;, &quot;segoe wp&quot;, segoe, device-segoe, tahoma, helvetica, arial, sans-serif; font-size: 10.5pt; color: rgba(33, 33, 33, 1)\"><span style=\"background-color: rgba(255, 255, 128, 1)\"><b>Test Rich Text</b></span><b>. dsadas</b></div>"
//   },{
//     "ODataEtag": null,
//     "SystemId": "57247f8e-f7d0-ee11-9078-0022482c3ec0",
//     "Code": "12",
//     "Description": "aaaa",
//     "MarketingText":  "<div style=\"font-family: &quot;segoe ui&quot;, &quot;segoe wp&quot;, segoe, device-segoe, tahoma, helvetica, arial, sans-serif; font-size: 10.5pt; color: rgba(33, 33, 33, 1)\"><span style=\"background-color: rgba(255, 255, 128, 1)\"><b>Test Rich Text</b></span><b>. dsadas</b></div>"
//   },{
//     "ODataEtag": null,
//     "SystemId": "57247f8e-f7d0-ee11-9078-0022482c3ec0",
//     "Code": "12",
//     "Description": "aaaa",
//     "MarketingText":  "<div style=\"font-family: &quot;segoe ui&quot;, &quot;segoe wp&quot;, segoe, device-segoe, tahoma, helvetica, arial, sans-serif; font-size: 10.5pt; color: rgba(33, 33, 33, 1)\"><span style=\"background-color: rgba(255, 255, 128, 1)\"><b>Test Rich Text</b></span><b>. dsadas</b></div>"
//   },{
//     "ODataEtag": null,
//     "SystemId": "57247f8e-f7d0-ee11-9078-0022482c3ec0",
//     "Code": "12",
//     "Description": "aaaa",
//     "MarketingText":  "<div style=\"font-family: &quot;segoe ui&quot;, &quot;segoe wp&quot;, segoe, device-segoe, tahoma, helvetica, arial, sans-serif; font-size: 10.5pt; color: rgba(33, 33, 33, 1)\"><span style=\"background-color: rgba(255, 255, 128, 1)\"><b>Test Rich Text</b></span><b>. dsadas</b></div>"
//   },{
//     "ODataEtag": null,
//     "SystemId": "57247f8e-f7d0-ee11-9078-0022482c3ec0",
//     "Code": "12",
//     "Description": "aaaa",
//     "MarketingText":  "<div style=\"font-family: &quot;segoe ui&quot;, &quot;segoe wp&quot;, segoe, device-segoe, tahoma, helvetica, arial, sans-serif; font-size: 10.5pt; color: rgba(33, 33, 33, 1)\"><span style=\"background-color: rgba(255, 255, 128, 1)\"><b>Test Rich Text</b></span><b>. dsadas</b></div>"
//   },{
//     "ODataEtag": null,
//     "SystemId": "57247f8e-f7d0-ee11-9078-0022482c3ec0",
//     "Code": "12",
//     "Description": "aaaa",
//     "MarketingText":  "<div style=\"font-family: &quot;segoe ui&quot;, &quot;segoe wp&quot;, segoe, device-segoe, tahoma, helvetica, arial, sans-serif; font-size: 10.5pt; color: rgba(33, 33, 33, 1)\"><span style=\"background-color: rgba(255, 255, 128, 1)\"><b>Test Rich Text</b></span><b>. dsadas</b></div>"
//   },{
//     "ODataEtag": null,
//     "SystemId": "57247f8e-f7d0-ee11-9078-0022482c3ec0",
//     "Code": "12",
//     "Description": "aaaa",
//     "MarketingText":  "<div style=\"font-family: &quot;segoe ui&quot;, &quot;segoe wp&quot;, segoe, device-segoe, tahoma, helvetica, arial, sans-serif; font-size: 10.5pt; color: rgba(33, 33, 33, 1)\"><span style=\"background-color: rgba(255, 255, 128, 1)\"><b>Test Rich Text</b></span><b>. dsadas</b></div>"
//   },
// ];

const CouponsGrid = () => {

    const { coupons, couponsLoading } = useGetCoupons();
    const parseHTML = (htmlString:string) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
      };
      
    if(couponsLoading) return <Loader/>
    return (
        <div style={{ padding: "25px"}}> {/**width: "100%", height: "100%", */}
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '50px' }}>
            {coupons?.map(coupon => (
                <div key={coupon.SystemId} style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                {ReactHtmlParser(coupon.MarketingText)}
                </div>
            ))}
            </div>
        </div>
    );
};

export default CouponsGrid;
