import Loader from "../../components/UI/loader/Loader";
import { useParams } from "react-router-dom";
import { useGetUserSalesOrderDetails } from "../../hooks/useGetUserSalesOrderDetails";
import { OrderDetails } from "../../models/app";
import useIsMobile from "../../hooks/useIsMobile";

export default function UserOrderDetailsPage() {
  const { OrderId } = useParams<string>();
  const { web } = useIsMobile();

  const { res, loading, orderDetails } = useGetUserSalesOrderDetails(
    OrderId ?? ""
  );

  return (
    <div>
      {loading && <Loader />}
      <div className="boxed_wrapper" style={{ paddingTop: "10px" }}>
        <section className="cart-section p_relative  pb_150">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 table-column">
                {/* Display relevant data from the res object */}
                <div>
                  <p>Order Number: {res?.number}</p>
                  <p>Customer Name: {res?.customerName}</p>
                  <p>Order Date: {res?.orderDate}</p>
                  <p>status: {res?.documentStatus}</p>
                  <p style={{fontWeight:700 , color:'#888', fontSize:'1.2rem'}}>Order Details: </p>
                  {/* Add more relevant data as needed */}
                </div>
                <div className="table-outer">
                  <table className="cart-table">
                    <thead className="cart-header">
                      <tr>
                        <th>&nbsp;</th>
                        <th className="prod-column">Product</th>
                        {web && (
                          <>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                          </>
                        )}
                        <th className="price">Price</th>
                        <th className="quantity">Quantity</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetails &&
                        orderDetails?.map(
                          (order: OrderDetails, index: number) =>
                            order.attachedtoSequenceNo === 0 ? (
                              <tr
                                style={{
                                  borderBottom: "0px",
                                  borderTop: "1px solid #e6e6e6",
                                }}
                                key={index}
                              >
                                <td colSpan={web?4:2} className="prod-column">
                                  <div
                                    className="prod-title"
                                    style={{ color: "#E7131A" }}
                                  >
                                    {order.description} <br />
                                  </div>
                                </td>
                                <td
                                  className="price"
                                  style={{ color: "#E7131A" }}
                                >
                                  ${order.unitPrice}
                                </td>
                                <td
                                  className="qty"
                                  style={{
                                    color: "#E7131A",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  {order.quantity}
                                </td>

                                <td
                                  className="sub-total"
                                  style={{ color: "#E7131A" }}
                                >
                                  ${order.netAmount}
                                </td>
                              </tr>
                            ) : (
                              <tr
                                style={{ borderBottom: "0px", padding: "0px" }}
                              >
                                <td
                                  colSpan={web?4:2}
                                  className="prod-column"
                                  style={{ padding: "0px" }}
                                >
                                  <div
                                    className="prod-title"
                                    style={{ color: "#626b74" }}
                                  >
                                    {order.description} <br />
                                  </div>
                                </td>
                                <td
                                  className="price"
                                  style={{ color: "#626b74", padding: "0px" }}
                                >
                                  ${order.unitPrice}
                                </td>
                                <td
                                  className="qty"
                                  style={{
                                    color: "#626b74",
                                    padding: "0px",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  {order.quantity}
                                </td>

                                <td
                                  className="sub-total"
                                  style={{ color: "#626b74", padding: "0px" }}
                                >
                                  ${order.netAmountIncludingTax}
                                </td>
                              </tr>
                            )
                        )}
                      <tr
                        style={{
                          borderBottom: "0px",
                          borderTop: "1px solid #e6e6e6",
                        }}
                      >
                        <td colSpan={web?4:2} >
                          <div
                            // className="prod-title"
                            style={{ color: "#E7131A" }}
                          >
                            Total Amount (before taxes)
                          </div>
                        </td>
                        <td className="price" style={{ color: "#E7131A", padding:'1rem 0rem' }}></td>
                        <td className="qty" style={{ color: "#E7131A", padding:'1rem 0rem' }}></td>

                        <td className="sub-total" style={{ color: "#E7131A", padding:'1rem 0rem' }}>
                          ${res && (res?.totalAmountExcludingTax ?? "")}
                        </td>
                      </tr>
                      <tr
                      
                        style={{
                          borderBottom: "0px",
                          // borderTop: "1px solid #e6e6e6",
                        }}
                      >
                        <td colSpan={web?4:2} >
                          <div
                            // className="prod-title"
                            style={{ color: "#E7131A" }}
                          >
                            Tax Amount <br />
                          </div>
                        </td>
                        <td className="price" style={{ color: "#E7131A" , padding:'1rem 0rem'}}></td>
                        <td className="qty" style={{ color: "#E7131A", padding:'1rem 0rem' }}></td>

                        <td className="sub-total" style={{ color: "#E7131A", padding:'1rem 0rem' }}>
                          ${res && (res?.totalTaxAmount ?? "")}
                        </td>
                      </tr>
                      <tr
                        style={{
                          borderBottom: "0px",
                          borderTop: "1px solid #e6e6e6",
                        }}
                      >
                        <td colSpan={web?4:2} >
                          <div
                            
                            style={{ color: "#E7131A" }}
                          >
                            Total Amount (tax included) <br />
                          </div>
                        </td>
                        <td className="price" style={{ color: "#E7131A" , padding:'1rem 0rem' }}></td>
                        <td className="qty" style={{ color: "#E7131A", padding:'1rem 0rem' }}></td>

                        <td className="sub-total" style={{ color: "#E7131A", padding:'1rem 0rem' }}>
                          ${res && (res?.totalAmountIncludingTax ?? "")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
