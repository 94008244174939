import { ThemeOptions } from "@mui/material/styles";

const lightThemeOptions: ThemeOptions = {
  direction: "ltr",
  palette: {
    primary: {
      light: "#2b313e",
      main: "#2b313e",
      dark: "#191f2b",
      contrastText: "#fff",
    },
    secondary: {
      light: "#f7613f",
      main: "#e35711",
      dark: "#c53a1b",
      contrastText: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#F9F9F9",
    },
  },
  shape: {
    borderRadius: 0,
  },
};

export default lightThemeOptions;
