// useApiHook.ts

import { useState, useEffect } from 'react';
import { getAdvancedSearchFilters } from '../services/GetSearchOptions';
import { SearchFiltersApiResponse } from '../models/app';

export const useSearchOptions = () => {
  const [searchOptions, setSearchOptions] = useState<SearchFiltersApiResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        const apiResponse = await getAdvancedSearchFilters();
        setSearchOptions(apiResponse);
      } catch (error) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { searchOptions, loading, error };
};
