import { useState } from "react";
import { SearchFiltersApiResponse } from "../models/app";


const usePorfileOptions = (setErrorAlert:any) => {
  const [profiles, setProfiles] = useState<any[]>([]);  
  const [profilesRear, setProfilesRear] = useState<any[]>([]);  
  const [profilesLoading, setLoading] = useState(false);
  const [profileError, setProfilesError] = useState<any>(null);
  const [profileErrorRear, setProfilesErrorRear] = useState<any>(null);
  
  const fetchProfileData = async (data:SearchFiltersApiResponse,width: any, type: string) => {
    try {
      setLoading(true);
      // const data = await GetProfileOptions(width);
      const results = data.Data.ratioOptionsByWidth[width].sort((a:any,b:any)=>a-b);
      if((results.length === 0)){
        type === "Front"?setProfilesError("No Data for this width size"):setProfilesErrorRear("No Data for this width size");
      }
      else{
        type === "Front"?setProfilesError(null):setProfilesErrorRear(null);
        if(setErrorAlert){
          setErrorAlert(true);
        }
      }
      
      type === "Front"? setProfiles(results): setProfilesRear(results);
      
      setLoading(false);
    } catch (error:any) {
      setProfilesError(error);
    }
  };
  
  return {
    profiles,
    profilesRear,
    profilesLoading,
    profileError,
    profileErrorRear,
    fetchProfileData,
    setProfilesError,
    setProfilesErrorRear,
  };
};

export default usePorfileOptions;
