import { useNavigate } from "react-router-dom";
import {  SearchInput } from "../models/app";
import { useState } from "react";

export interface CatalogFlowQueryParams {
  searchInput: string;
  userSystemId: string;
  catalog: boolean;
}

const useCatalogFlow = () => {

    const navigate = useNavigate();
    const [catalogError, setError] = useState<string | null>(null);

    const HandleSearchClick = (searchInput: SearchInput, userSystemId:string, value:string) =>{
        if(value.length===0){
            setError("Please Select Raw Size");
        }
        else{
            const userSystemid = userSystemId!==null && userSystemId?.length !== 0?userSystemId: localStorage.getItem('userSystemId');
            localStorage.setItem('userSystemId', userSystemid??"");
            const params: CatalogFlowQueryParams = {
                searchInput: JSON.stringify(searchInput)?? "",
                userSystemId: userSystemid?? "",
                catalog: true,
            }
                const queryString = new URLSearchParams(params as any).toString();
                navigate(`/shop?${queryString}`);
        }
    }

    return { HandleSearchClick, catalogError };
};

export default useCatalogFlow;
    