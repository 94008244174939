import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const EmptyShop = () => {
  return (
    <Container maxWidth="sm" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
      <Typography variant="h5" component="h2" gutterBottom>
        No Tires Matching Your Filters
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        Change Your Filter Selections 
        Or 
      </Typography>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Button variant="contained" color="primary">
          Go Back To Home Page
        </Button>
      </Link>
    </Container>
  );
};

export default EmptyShop;
