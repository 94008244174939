import { useState } from 'react';
import { Model } from '../models/app';
import { GetModelsByBrands } from '../services/GetModelsByBrand';

export const useGetModels = () => {
  const [models, setModels] = useState<any[] | null>(null);
  const [modelsLoading, setLoading] = useState(true);
  const [modelsError, setError] = useState<string | null>(null);


const getModels = async (brand: string) => {
    try {
        setLoading(true);
        
        const apiResponse:any  = await GetModelsByBrands( brand);
        const modelsList:Model[] = apiResponse?.Data;
        // console.log(modelsList);
        setModels(modelsList);
        setLoading(false);

    } catch (error) {
        setError('Error fetching data');
    } finally {
        setLoading(false);
    }
    };

  return { getModels, models, modelsLoading, modelsError };
};    