import { useNavigate } from "react-router-dom";
import { Location, NewValidate, SearchInput } from "../models/app";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/ducks";

export interface QueryParams {
  searchInput: string;
  searchInputRear: string;
  userSystemId: string;
  catalog: boolean;
}

const useHomeBanner = (setErrorAlert: any) => {


    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const locationsStore = useSelector((state: RootState) => state?.store?.locations);
    const selectedLocationStore = useSelector((state: RootState) => state?.store?.selectedLocation);
    const userSystemIdStore = useSelector((state: RootState) => state?.store?.userSystemId);

    const [allFieldsValid, setAllFieldsValid] = useState(false);
    const [locations, setLocations] = useState<Location[]>(locationsStore);
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(selectedLocationStore);
    const [userSystemId, setUserSystemId] = useState(userSystemIdStore);
    const [clickError, setClickError] = useState<string | null>(null);
    const [Loading, setLoading] = useState(true);    


    useEffect(() => {
      setLoading(true);
      setSelectedLocation(selectedLocationStore); 
      setUserSystemId(userSystemIdStore);
      setLoading(false);
    }, [selectedLocationStore, userSystemIdStore]);

    const textArray = [selectedLocationStore.text1HomePage, selectedLocationStore.text2HomePage, selectedLocationStore.text3HomePage]
    
    
    

    const checkFormValidity = (searchInputValidate:NewValidate, searchInputValidateRear:NewValidate, differentRearSize:boolean, differentRearSizeRawSize:boolean) => {
        const result: boolean = (differentRearSize) ? (searchInputValidateRear.Ratio && searchInputValidateRear.Wheel && searchInputValidateRear.Width && searchInputValidate.Ratio && searchInputValidate.Width && searchInputValidate.Wheel) : (searchInputValidate.Ratio && searchInputValidate.Width && searchInputValidate.Wheel);
        const resultRawSize: boolean = (differentRearSizeRawSize) ? ((searchInputValidate.RawSize && searchInputValidateRear.RawSize)) : (searchInputValidate.RawSize);
        return {flag: (result || resultRawSize), searchType: (result===true&&resultRawSize===true)?"Both": result===true?"By Tire Size":resultRawSize===true?"By Raw Size":"Neither"};
    }
      
    const HandleSearchClick = (selectedLocation: any, setLocationModalOpen: any, searchInput: SearchInput, searchInputRear: SearchInput, userSystemId:string, searchInputValidate:NewValidate, searchInputValidateRear:NewValidate, differentRearSize:boolean, differentRearSizeRawSize:boolean, searchType1:string) =>{
        const result = checkFormValidity(searchInputValidate, searchInputValidateRear, differentRearSize, differentRearSizeRawSize);
        const formIsValid = result.flag;
        const searchType = result.searchType;
        setAllFieldsValid(formIsValid);
        // console.log("searchType", searchType, "formIsValid", formIsValid, "searchInput", searchInput, "searchInputRear", searchInputRear, "differentRearSize", differentRearSize, "searchInputValidate", searchInputValidate, "searchInputValidateRear", searchInputValidateRear);

        const userSystemid = userSystemId!==null && userSystemId?.length !== 0?userSystemId: localStorage.getItem('userSystemId');


        var data = {...searchInput};
        var dataRear = {...searchInputRear};

        if(searchType==="By Raw Size"){
          
          const newInput = {...searchInput};
          newInput.Width = "";
          newInput.Ratio = "";
          newInput.Wheel = "";
          newInput.Season = "";
          newInput.speedRating = "";
          newInput.loadIndex = "";
          data = newInput
          const newInputRear = {...searchInputRear};
          newInputRear.Width = "";
          newInputRear.Ratio = "";
          newInputRear.Wheel = "";
          newInput.Season = "";
          newInputRear.speedRating = "";
          newInputRear.loadIndex = "";
          dataRear = newInputRear;

          if(!(differentRearSizeRawSize)){
            dataRear = {
              Width: "",
              Ratio: "",
              Wheel: "",
              Season: "",
              speedRating: "",
              loadIndex: "",
              RawSize: "",
            };
          }
          else if((differentRearSizeRawSize)){
            dataRear = {
              Width: "",
              Ratio: "",
              Wheel: "",
              Season: "",
              speedRating: "",
              loadIndex: "",
              RawSize: newInputRear.RawSize,
            };
          }
          }else if(searchType==="By Tire Size"){
            const newInput = {...searchInput};
            newInput.RawSize = "";
            data = newInput;
            if(!differentRearSize){
              dataRear = {
                Width: "",
                Ratio: "",
                Wheel: "",
                Season: "",
                speedRating: "",
                loadIndex: "",
                RawSize: "",
              };
            }
          }else if(searchType==="Both"){
            const newInput = {...searchInput};
            data = newInput;
            if(!differentRearSize){
              dataRear = {
                Width: "",
                Ratio: "",
                Wheel: "",
                Season: "",
                speedRating: "",
                loadIndex: "",
                RawSize: differentRearSizeRawSize?searchInputRear.RawSize:"",
              };
            }
          }

        if(formIsValid){
            // console.log(data, dataRear);
            if(selectedLocation.length === 0){
              setLocationModalOpen(true);
            }
            else{
              localStorage.setItem('userSystemId', userSystemid??"");
              const params: QueryParams = {
                searchInput: JSON.stringify(data)?? "",
                searchInputRear: JSON.stringify(dataRear)?? "",
                userSystemId: userSystemid?? "",
                catalog: false,
              };
              // console.log("searchType", searchType, "formIsValid", formIsValid, "searchInput", data, "searchInputRear", dataRear, "differentRearSize", differentRearSize, "searchInputValidate", searchInputValidate, "searchInputValidateRear", searchInputValidateRear);
              const queryString = new URLSearchParams(params as any).toString();
              navigate(`/shop?${queryString}`);
              window.location.reload();
            }
        }
        else{
          setClickError("Please Select All Fields");
            setErrorAlert(true);
        }
    }

    return { HandleSearchClick, allFieldsValid, locations, selectedLocation, setSelectedLocation, setUserSystemId, userSystemId, textArray, clickError, setClickError };
};

export default useHomeBanner;
    