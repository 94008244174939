import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import shape1 from "../../assets/images/shape/shape-176.png"; // Adjust paths as necessary
import shape2 from "../../assets/images/shape/shape-56.png";

interface PageTitleProps {
    title: string
  };


const PageTitle: React.FC<PageTitleProps> = ({title}) => {
    
  return (
    <Box sx={{ padding: "10px", marginBottom: "10px", marginTop: "-10px", height: "200px", paddingRight: "10vw", paddingLeft: "10vw" }}>
      <section className="page-title p_relative centred">
        <div className="pattern-layer" style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <div className="shape-1 p_absolute l_180 rotate-me" style={{ backgroundImage: `url(${shape1})`, width: "180px", height: "180px", borderRadius: "50%", marginTop: "-20px" }}></div>
          <div className="shape-2 p_absolute r_200 float-bob-y" style={{ backgroundImage: `url(${shape2})`, width: "150px", height: "150px" }}></div>
        </div>
        <div className="auto-container">
          <div className="content-box">
            <Typography variant="h1" sx={{ fontSize:  { xs: '70px', sm: '80px' }, lineHeight: '70px', fontWeight: 'bold', mb: 2, display: 'block' }}>
              {title}
            </Typography>
          </div>
        </div>
      </section>
    </Box>
  );
};

export default PageTitle;
