// ducks/index.ts
import { combineReducers } from '@reduxjs/toolkit';
import cartReducer from './app';

const rootReducer = combineReducers({
  store: cartReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
