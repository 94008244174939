import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import "./styles";
import { hydrate, render } from "react-dom";
import reportWebVitals from "./reportWebVitals";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);
const rootElement: any = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  root.render(<App />);
}
reportWebVitals();
