import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LandingPage from "../../pages/LandingPage/LandingPage";

const AuthGuardLogin = ({ children }:any) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate()
  useEffect(() => {
    // Simulate checking if user is logged in
    const user = JSON.parse(localStorage.getItem("user") ?? `""`);
    const isLoggedIn = user && user !== "" && user && user !== "guest";  
    if(isLoggedIn) navigate('/')
    setIsLoggedIn(isLoggedIn);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isLoggedIn? children : <LandingPage/> ;
};

export default AuthGuardLogin;
