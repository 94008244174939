import { Suspense,  useEffect, useState } from "react";
import { Route, Routes, ScrollRestoration, useLocation } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
import ShopPage from "./pages/ShopPage/ShopPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import CartSection from "./pages/cartPage/CartPage";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import { useDispatch, useSelector } from "react-redux";
import CouponsPage from "./pages/CouponsPage/CouponsPage";
import SignUpPage from "./pages/SignupPage/SignUpPage";
import LoginPage from "./pages/LoginPage/LoginPage";

import AccountPage from "./pages/AccountPage/AccountPage";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";

import ModelsPage from "./pages/ModelsPage/ModelsPage";
import ConfirmOrderPage from "./pages/GlobalPages/ConfirmOrderPage";
import useIsMobile from "./hooks/useIsMobile";
import { useGetBrands } from "./hooks/useGetBrands";
import { useGetLocations } from "./hooks/useGetLocations";
import LocationsPage from "./pages/LocationPage/LocationPage";
import { RootState } from "./store/ducks";
import {
  updateUser,
  updateUserSystemId,
} from "./store/ducks/app";
import AuthGuard from "./components/authGuard/AuthGuard";
import NotFound404Page from "./pages/GlobalPages/NotFound404Page";
import AuthGuardLogin from "./components/authGuard/AuthGuardLogin";
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";
import { CircularProgress, Grid } from "@mui/material";
import UserOrderDetailsPage from "./pages/UserSalesOrderPage/UserOrderDetailsPage";
import UserOrdersPage from "./pages/UserSalesOrderPage/UserOrdersPage";
import UserInvoicesDetailsPage from "./pages/UserInvoicesPage/UserInvoicesDetailsPage";
import UserInvoicesPage from "./pages/UserInvoicesPage/UserInvoicesPage";
import { Location } from "./models/app";
import Footer from "./components/Footer";
import NewSearch from "./components/NewSearch/NewSearch";
import MobileHeader from "./components/mobileMenu/MobileHeader";
import Header from "./components/header/Header";

function Dashboard() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isMobile, isTablet, web } = useIsMobile();
  const locationsStore: Location[] = useSelector(
    (state: RootState) => state?.store?.locations
  );
  const { locationsLoading , fetchData } = useGetLocations();

  const { brands } = useGetBrands();
  useEffect(()=>{
    fetchData();
  
  },[])
  const userStore = useSelector((state: RootState) => state?.store?.user);

  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const handleOpenMobileMenu = () => {
    // console.log("?", openMobileMenu);
    setOpenMobileMenu(!openMobileMenu);
  };

  useEffect(() => {
    if (userStore !== null && userStore !== "" && userStore !== "guest") {
      dispatch(updateUserSystemId(userStore.id));
    } else if (
      userStore !== null &&
      userStore === ""
    ) {
      dispatch(updateUser("guest"));
    }
  }, [location, userStore]);

  // const MobileHeader = lazy(()=>import('./components/mobileMenu/MobileHeader'));
  // const Header = lazy(()=>import('./components/header/Header'));
  // const Footer = lazy(()=>import('./components/Footer'));
  // const NewSearch = lazy(()=>import('./components/NewSearch/NewSearch'));
  
    return (
      <div className="Dashboard">
            <ScrollRestoration />

      <Suspense fallback={<div style={{height:'100vh' , display:'flex' , alignItems:'center' , justifyContent:'center'}}><CircularProgress /></div>}>
      
          {/* {(typeof window === "undefined" || typeof isMobile === "undefined" )?  <Loader />
           : */}
           {((isMobile===true) || (isTablet===true)) ? (
            <MobileHeader
              handleOpenMobileMenu={handleOpenMobileMenu}
              brands={brands}
              locationsLoading={locationsLoading}
            />
          ) : (
            <Header
              handleOpenMobileMenu={handleOpenMobileMenu}
              brands={brands}
              locationsLoading={locationsLoading}
            />
          )
          } 
            {/* <SidebarDrawer
              open={openMobileMenu}
              setOpen={handleOpenMobileMenu}
              brands={brands}
              // locations={locations}
            /> */}
          
        
        {location.pathname !== "/" && (
          <Grid
            container
            sx={{
              padding: "30px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              <NewSearch />
            </Grid>
          </Grid>
        )}

        <Routes>
          <Route path="/" element={<LandingPage />}/>
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/cart" element={<CartSection />} />
          <Route path="/aboutUs" element={<AboutUsPage />} />
          {/* <Route path="/location" element={<LocationsPage />} /> */}
          <Route path="/coupons" element={<CouponsPage />} />
          <Route
            path="/signup"
            element={
              <AuthGuardLogin>
                <SignUpPage />
              </AuthGuardLogin>
            }
          />
          <Route
            path="/login"
            element={
              <AuthGuardLogin>
                <LoginPage />
              </AuthGuardLogin>
            }
          />
          <Route
            path="/accountPage"
            element={
              <AuthGuard>
                <AccountPage />
              </AuthGuard>
            }
          />
          <Route
            path="/forgotPass"
            element={
              <AuthGuardLogin>
                <ResetPasswordPage />{" "}
              </AuthGuardLogin>
            }
          />
          <Route
            path="/resetpassword"
            element={
              <AuthGuardLogin>
                <ResetPasswordPage />{" "}
              </AuthGuardLogin>
            }
          />
          <Route
            path="/myInvoices"
            element={
              <AuthGuard>
                <UserInvoicesPage />
              </AuthGuard>
            }
          />
          <Route
            path="/invoiceDetails/:invoiceId"
            element={
              <AuthGuard>
                <UserInvoicesDetailsPage />
              </AuthGuard>
            }
          />
          <Route
            path="/mySalesOrders"
            element={
              <AuthGuard>
                <UserOrdersPage />
              </AuthGuard>
            }
          />
          <Route
            path="/salesOrderDetails/:OrderId"
            element={
              <AuthGuard>
                <UserOrderDetailsPage />
              </AuthGuard>
            }
          />
          <Route path="/models" element={<ModelsPage />} />
          {/* <Route
                path={`/Tire-Brands/:Brand`} element={<ModelsPage />}
              /> */}
          {/* {brands?.map((item: any, index:number) => {
            return (
              <Route
                key={item.Code + " " + index}
                path={`/Tire-Brands/${item.Code.replace(" ","-")}`} element={<ModelsPage selectedBrand={item.Code}/>}
              />
            );
          })} */}
          {locationsStore?.map((item: Location, index:number) => {
            return (
              <Route
                key={item.name + " " + index}
                path={`/location/${item.name.replace(" ","-")}`} element={<LocationsPage location={item}/>}
              />
            );
          })}
          <Route path="/confirmOrder" element={<ConfirmOrderPage />} />
          <Route path="/Error404" element={<NotFound404Page />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
        </Suspense>
        </div>
    );
  }

export default Dashboard;
