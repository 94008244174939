import { useState, lazy } from "react";
import useHomeBanner from "../../hooks/useHomeBanner";
import { Grid, useTheme } from "@mui/material";
import HomePageFilters from "../HomePageFilters/HomePageFilters";
// import { useSelector } from "react-redux";
// import { RootState } from "../../store/ducks";
import useIsMobile from "../../hooks/useIsMobile";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { RootState } from "../../store/ducks";
const CouponsSlider = lazy(
  () => import("../../components/Coupons/CouponsSlider")
);
const HomeBanner = () => {
  const theme = useTheme();

  const [errorAlert, setErrorAlert] = useState(false);

  const { textArray } = useHomeBanner(setErrorAlert);

  const selectedLocationStore = useSelector(
    (state: RootState) => state?.store?.selectedLocation
  );

  // const [activeIndex, setActiveIndex] = useState(0);
  const { isMobile, isTablet } = useIsMobile();

  // const config =
  //   "<style>@media only screen and (min-width: 260px) and (max-width: 740px){h1{font-size: 20px;}h2{font-size: 18px;}h3{font-size: 16px;}h4{font-size: 16px;}h5{font-size: 16px;}h6{font-size: 16px;}p{font-size: 16px;}a{font-size: 16px;}i{font-size: 16px;}span{font-size: 16px;}b{font-size: 16px;}br{height:0px;}}</style>";
  const parseHTML = (htmlString: string) => {
    return htmlString ? (
      <div
        // dangerouslySetInnerHTML={{
        //   __html: isMobile
        //     ? config + " " + htmlString?.replaceAll("<br>", "")
        //     : htmlString,
        // }}
        dangerouslySetInnerHTML={{
          __html: htmlString,
        }}
      />
    ) : (
      <></>
    );
  };
  console.log(selectedLocationStore?.name);
  return (
    <section className="banner banner-one" style={{ marginBottom: "3rem" }}>
      <div className="auto-container">
        <Grid container spacing={2}>
          {/* Carousel Section */}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {/* {selectedLocationStore?.homePageImage &&  */}
            {/* <img src="/HomeImage.webp" alt="Home Banner" style={{width:'100%',height:'500px',objectFit:(isMobile||isTablet)?'contain':'cover'}}></img> */}
            <div
              role="img"
              aria-label="home banner background"
              className={`banner-left-container ${
                selectedLocationStore?.name?.toLowerCase() ?? "covina"
              }`}
              style={{
                // backgroundImage: `url(${selectedLocationStore?.homePageImage})`,
                // backgroundImage: `${require('/HomeImage.webp')}`,
                // backgroundImage:`url('/bannerImage.webp')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: isMobile || isTablet ? "contain" : "cover",
              }}
            >
              {isMobile ? (
                <div className="banner-item">
                  <div
                    className="banner-content"
                    style={{ minHeight: "200px" }}
                  >
                    {/* { ReactHtmlParser("<div style=\"text-align: center\"><br></div><div style=\"text-align: center\"><br></div><div style=\"text-align: center\"><br></div><div style=\"text-align: center\"><br></div><div style=\"text-align: center\"><a href=\"https://www.tirezoneonline.com/\" title=\"Thank you for visiting tires on this website is under construction please Click this link or visit us at tirezoneonline.com\" style=\"font-size: 10.5pt; background-color: var(--bc-minflat)\"><span style=\"background-color: rgba(255, 255, 128, 1); color: rgba(0, 36, 81, 1); font-size: 28pt\"><b>Thank you for visiting Tire Zone this website is</b></span></a></div><div style=\"text-align: center\"><br></div><div style=\"text-align: center\"><a href=\"https://www.tirezoneonline.com/\" title=\"Thank you for visiting tires on this website is under construction please Click this link or visit us at tirezoneonline.com\" style=\"font-size: 10.5pt; background-color: var(--bc-minflat)\"><span style=\"background-color: rgba(255, 255, 128, 1); color: rgba(0, 36, 81, 1); font-size: 28pt\"><b>&nbsp;under construction please Click this link or</b></span></a></div><div style=\"text-align: center\"><br></div><div style=\"text-align: center\"><a href=\"https://www.tirezoneonline.com/\" title=\"Thank you for visiting tires on this website is under construction please Click this link or visit us at tirezoneonline.com\" style=\"font-size: 10.5pt; background-color: var(--bc-minflat)\"><span style=\"background-color: rgba(255, 255, 128, 1); color: rgba(0, 36, 81, 1); font-size: 28pt\"><b>&nbsp;visit us at tirezoneonline.com</b></span></a></div>") } */}

                    {ReactHtmlParser(textArray?.[2] ?? "")}
                  </div>
                </div>
              ) : (
                // <Carousel
                //   index={ activeIndex }
                //   animation="slide"
                //   onChange={ (index: any) => setActiveIndex(index) }
                //   indicators={ false }
                //   navButtonsAlwaysInvisible
                // >
                textArray
                  ?.slice(0, 2)
                  .filter(
                    (text): text is string =>
                      text !== null &&
                      text !== undefined &&
                      text.trim().length > 0
                  )
                  .map((item, index) => (
                    <div className="banner-item" key={index}>
                      {" "}
                      {/**  */}
                      <div className="banner-content">
                        {/* { ReactHtmlParser("<div style=\"text-align: center\"><br></div><div style=\"text-align: center\"><br></div><div style=\"text-align: center\"><br></div><div style=\"text-align: center\"><br></div><div style=\"text-align: center\"><a href=\"https://www.tirezoneonline.com/\" title=\"Thank you for visiting tires on this website is under construction please Click this link or visit us at tirezoneonline.com\" style=\"font-size: 10.5pt; background-color: var(--bc-minflat)\"><span style=\"background-color: rgba(255, 255, 128, 1); color: rgba(0, 36, 81, 1); font-size: 28pt\"><b>Thank you for visiting Tire Zone this website is</b></span></a></div><div style=\"text-align: center\"><br></div><div style=\"text-align: center\"><a href=\"https://www.tirezoneonline.com/\" title=\"Thank you for visiting tires on this website is under construction please Click this link or visit us at tirezoneonline.com\" style=\"font-size: 10.5pt; background-color: var(--bc-minflat)\"><span style=\"background-color: rgba(255, 255, 128, 1); color: rgba(0, 36, 81, 1); font-size: 28pt\"><b>&nbsp;under construction please Click this link or</b></span></a></div><div style=\"text-align: center\"><br></div><div style=\"text-align: center\"><a href=\"https://www.tirezoneonline.com/\" title=\"Thank you for visiting tires on this website is under construction please Click this link or visit us at tirezoneonline.com\" style=\"font-size: 10.5pt; background-color: var(--bc-minflat)\"><span style=\"background-color: rgba(255, 255, 128, 1); color: rgba(0, 36, 81, 1); font-size: 28pt\"><b>&nbsp;visit us at tirezoneonline.com</b></span></a></div>") } */}
                        {ReactHtmlParser(item)}
                      </div>
                    </div>
                  ))
                // </Carousel>
              )}
            </div>
            {/* } */}
          </Grid>
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            sx={{
              zIndex: "-100",
              [theme.breakpoints.down("xs")]: {
                marginTop: "-120px",
              },
              [theme.breakpoints.down("sm")]: {
                marginTop: "-100px",
              },
              [theme.breakpoints.down("md")]: {
                marginTop: "-120px",
              },
              [theme.breakpoints.up("lg")]: {
                marginTop: "-250px",
              },
            }}
          ></Grid>
          <Grid
            item
            xl={10}
            lg={10}
            md={10}
            sm={12}
            xs={12}
            sx={{
              zIndex: "1",
              [theme.breakpoints.down("xs")]: {
                marginTop: "-140px",
              },
              [theme.breakpoints.down("sm")]: {
                marginTop: "-120px",
              },
              [theme.breakpoints.down("md")]: {
                marginTop: "-120px",
              },
              [theme.breakpoints.up("lg")]: {
                marginTop: "-250px",
              },
            }}
          >
            <HomePageFilters />
          </Grid>
        </Grid>
      </div>

      <div
        className="auto-container"
        style={{ marginTop: "2rem", padding: "5px" }}
      >
        <CouponsSlider />
      </div>
    </section>
  );
};

export default HomeBanner;
