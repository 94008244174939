import { useState } from "react";
import { UpdateProfile } from "../services/UpdateProfile";

export const useUpdateProfile = () => {
  const [res, setRes] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const updateProfile = async (body: any) => {
    try {
      setLoading(true);

      
      const apiResponse = await UpdateProfile( body);

      if (apiResponse?.Success === 0) {
        setError(apiResponse.Message);
      } else {
        setRes(apiResponse.Message);
        const user = apiResponse?.Data
        const {password, ...newUser} = user
        localStorage.setItem("user", JSON.stringify(newUser));
        window.location.href = "/accountPage"
      }
    } catch (error: any) {
      setError("Error Updating Info");
    } finally {
      setLoading(false);
    }
  };

  return { updateProfile, res, loading, error };
};
