import React, { useEffect, useState } from "react";
import CartTable from "../../components/cart/cartTable/CartTable";

import { useDispatch, useSelector } from "react-redux";
import EmptyCart from "../../components/cart/EmptyCart";
import { useCart } from "../../hooks/useCart";
import { RootState } from "../../store/ducks";
import { usePlaceOrder } from "../../hooks/usePlaceOrder";
import {
  FormControlLabel,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { applyDiscount } from "../../store/ducks/app";
import Loader from "../../components/UI/loader/Loader";
import { Helmet } from "react-helmet";

const CartSection = () => {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state?.store?.user);
  const discount = useSelector((state: any) => state?.store?.discountApplied);
  // const discountPercentage = useSelector(
  //   (state: any) => state?.store?.discountPercentage
  // );

  const [cartStored, setCartStored] = useState<any>(
    ((state: RootState) => state?.store?.cart) ?? []
  );
  // const [discountApplied, setDiscountApplied] = useState<any>(discount);
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState("");

  const {
    totalPrice,
    totalPriceWithoutTax,
    servicesTotal,
    updateCart,
    cartItems,
    discountApplied,
    setDiscountApplied,
    setCartItems,
    calculateTotal,
    removeFromCart,
    calculateTaxesTotal,
    tax,
    tax1,
    tax2,
    tax1Description,
    tax2Description, 
    discountAmount,
  } = useCart(true);

  const { placeOrder, orders, ordersLoading, ordersError } = usePlaceOrder();

  // useEffect(()=>{
  //   console.log("discount", discount ?? "", "discountAmount", discountAmount ?? "");
  // },[]);

  const handleDiscountApplied = () => {
    // console.log(discountApplied);
    dispatch(applyDiscount(!discountApplied));
    // calculateTotal(discountApplied);
    setDiscountApplied(!discountApplied);
  };

  if (ordersLoading) return <Loader />;
  return (
    <div className="boxed_wrapper" style={{ paddingTop: "10px" }}>
      {/* <PageTitle title="CART" /> */}
      <Helmet>
        <title>Cart Page</title>
        <meta name="description" content="cart page" />
        <meta name="keywords" content="cart, shopping, items" />
        <meta name="author" content="Cart Page" />
        <meta name="cart-items" content={JSON.stringify(cartItems)} />
      </Helmet>
      {/* <!-- cart section --> */}
      <section className="cart-section p_relative  pb_150">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12 table-column">
              {cartItems && cartItems.length > 0 ? (
                <div className="table-outer">
                  <table className="cart-table">
                    <CartTable
                      updateCart={updateCart}
                      removeFromCart={removeFromCart}
                      cartItems={cartItems}
                      setCartItems={setCartItems}
                      calculateTotal={calculateTotal}
                      user={user}
                    />
                  </table>
                </div>
              ) : (
                // <h2 style={{textAlign:'center',margin:'5rem'}}> Cart is still Empty</h2>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <EmptyCart />
                </div>
              )}
            </div>
          </div>
          <br />
          {cartItems && cartItems.length > 0 && (
            <>
            <div className="cart-total">
              <div className="row">
                <div className="col-xl-5 col-lg-12 col-md-12 offset-xl-7 cart-column">
                  <div className="total-cart-box clearfix">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Grid container spacing={0}>
                        {discountAmount > 0 && (
                          <>
                            <Grid item xs={3}></Grid>
                            <Grid
                              item
                              xs={9}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event: any) =>
                                  event.stopPropagation()
                                }
                                onFocus={(event: any) =>
                                  event.stopPropagation()
                                }
                                control={
                                  <Switch
                                    checked={discountApplied}
                                    onChange={handleDiscountApplied}
                                    color="primary"
                                    sx={{
                                      "& .MuiSwitch-switchBase.Mui-checked": {
                                        color: "green",
                                        "& + .MuiSwitch-track": {
                                          backgroundColor: "green",
                                        },
                                      },
                                    }}
                                  />
                                }
                                label={
                                  discountApplied
                                    ? `Remove ${
                                        discountAmount ?? 0
                                      }% Cash Discount`
                                    : `Apply ${
                                        discountAmount ?? 0
                                      }% Cash Discount`
                                }
                                sx={{ marginLeft: "auto" }}
                              />
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid
                              item
                              xs={9}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Typography
                                fontWeight="bold"
                                fontSize={"14px"}
                                color={"#E7131A"}
                              >{`When payment is made in full by Cash only`}</Typography>
                            </Grid>
                          </>
                        )}
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", justifyContent: "flex-start" }}
                        >
                          <Typography
                            color={"black"}
                            fontWeight="bold"
                            fontSize={"28px"}
                          >{`Price Summary`}</Typography>
                        </Grid>
                      </Grid>
                    </div>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {/* <TableRow>
                          <TableCell sx={{fontWeight: "bold", fontSize: "20px"}}>Services:</TableCell>
                          <TableCell sx={{fontWeight: "bold", fontSize: "20px"}} align="right">${servicesTotal?.toFixed(2)}</TableCell>
                        </TableRow> */}
                          <TableRow>
                            <TableCell
                              sx={{ fontWeight: "bold", fontSize: "20px" }}
                            >
                              Sub Total:
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold", fontSize: "20px" }}
                              align="right"
                            >
                              ${totalPriceWithoutTax?.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          {tax > 0 && (
                            <TableRow>
                              <TableCell
                                sx={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                Taxes:
                              </TableCell>
                              <TableCell
                                sx={{ fontWeight: "bold", fontSize: "16px" }}
                                align="right"
                              >
                                ${tax.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          )}
                          {tax1 > 0 && tax1Description.length > 0 && (
                            <TableRow>
                              <TableCell
                                sx={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                {cartItems[0].additionalTax1Caption || ""}
                              </TableCell>
                              <TableCell
                                sx={{ fontWeight: "bold", fontSize: "16px" }}
                                align="right"
                              >
                                ${tax1.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          )}
                          {tax2 > 0 && tax2Description.length > 0 && (
                            <TableRow>
                              <TableCell
                                sx={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                {cartItems[0].additionalTax2Caption || ""}
                              </TableCell>
                              <TableCell
                                sx={{ fontWeight: "bold", fontSize: "16px" }}
                                align="right"
                              >
                                ${tax2.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow
                            sx={{
                              borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
                            }}
                          >
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                fontSize: "25px",
                                color: "#E7131A",
                              }}
                            >
                              Order Total:
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                fontSize: "25px",
                                color: "#E7131A",
                              }}
                              align="right"
                            >
                              ${totalPrice.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <br />
                    <TextField
                      fullWidth
                      label="Purchase Order # (Optional)"
                      variant="outlined"
                      value={purchaseOrderNumber}
                      onChange={(e) => setPurchaseOrderNumber(e.target.value)}
                      inputProps={{ maxLength: 34 }}
                      placeholder="Purchase Order # (Optional)"
                      sx={{ marginBottom: 2 }} 
                    />
                    <br/>
                    <a
                      className="theme-btn theme-btn-eight"
                      style={{ cursor: "pointer" }}
                      onClick={() => placeOrder("", purchaseOrderNumber)}
                    >
                      Proceed to Checkout <i className="icon-4"></i>{" "}
                      {/** href="cart.html"*/}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default CartSection;
