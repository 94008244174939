import React from "react";
import CouponsGrid from "../../components/Coupons/CouponsGrid";
import { Helmet } from "react-helmet";

const CouponsPage: React.FC = () => {
  return (
    <body>
      {/* <PageTitle title={"Coupons"}/> */}
      <Helmet>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5"
        />
        <meta name="format-detection" content="telephone=no" />

        <title>Discount Offers - Tire Zone</title>
        <meta
          name="description"
          content="Keep checking Tire Zone&#039;s Discounts Offers on Services and Tires."
        />
        
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Discount Offers - Tire Zone" />
        <meta
          property="og:description"
          content="Keep checking Tire Zone&#039;s Discounts Offers on Services and Tires."
        />
        <meta
          property="og:url"
          content="https://tirezoneusa.com/coupons/"
        />
        <meta property="og:site_name" content="Tire Zone" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/pages/Tire-Zone/167358669946020"
        />
        <meta
          property="article:modified_time"
          content="2022-12-06T21:44:43+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@tirezoneusa" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="1 minute" />
      </Helmet>
      <div
        className="boxed_wrapper"
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10vw",
          paddingRight: "10vw",
          paddingTop: "10px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            alignItems: "flex-start",
          }}
        >
          <h3
            className="d_block fs_60 lh_70 mb_10"
            style={{ fontSize: "25px" }}
          >
            PROMOTIONAL DISCOUNTS & COUPONS
          </h3>
          <p>
            Tire Zone offers several amazing deals and discounts to all
            customers.
          </p>
          <p style={{ fontWeight: "bold" }}>
            We offer FREE Flat Repair and FREE Alignment checks!
          </p>
          <p>
            Keep checking Tire Zone’s Discount Offers section below to find all
            current promotional discounts coupons and offers.
          </p>
        </div>
        <CouponsGrid />
      </div>
    </body>
  );
};

export default CouponsPage;
