/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { CatalogFlowSearchInput, Model } from "../../models/app";
import Loader from "../../components/UI/loader/Loader";
import EmptyShop from "../../components/EmptyShop";
import { Grid } from "@mui/material";
import { useGetModels } from "../../hooks/useGetModels";
import ModelsCard from "../../components/CatalogFlow/ModelsCard";
import { Helmet } from "react-helmet";
import { useGetBrands } from "../../hooks/useGetBrands";


const ModelsPage: React.FC<any> = ({ selectedBrand }: any) =>
{

  // const [params] = useSearchParams();
  const [brand, setBrand] = useState("");
  // const params = useParams();
  // const selectedBrand = params.selectedBrand || "default";

  const { getModels, models, modelsLoading, modelsError } =
    useGetModels();

  useEffect(() =>
  {
    // const selectedBrand = params?.get('selectedBrand');
    getModels(selectedBrand.replace('-', ' ') ?? "");
    setBrand(selectedBrand.replace('-', ' ') ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrand]);

  const [searchInput, setSearchInput] = useState<CatalogFlowSearchInput>({
    brand: brand ?? "",
    model: "",
    RawSize: "",
  });

  const [selectedFilters, setSelectedFilters] = useState<any>({});
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [runFlat, setRunFlat] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(Math.ceil(((models?.length ?? 0) / itemsPerPage)));
  const [indexOfLastItem, setIndexOfLastItem] = useState<number>((page === totalPages) ? (models?.length ?? 0) : (page * itemsPerPage));
  const [indexOfFirstItem, setIndexOfFirstItem] = useState<number>((indexOfLastItem - itemsPerPage));
  const [currentItems, setCurrentItems] = useState<Model[]>(models?.slice(indexOfFirstItem, indexOfLastItem) ?? []);
  //const { brands } = useGetBrands();

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) =>
  {
    setPage(newPage);
  };

  useEffect(() =>
  {
    setPage(1);
  }, [selectedFilters, runFlat]);

  useEffect(() =>
  {
    const totalModels = models?.length;
    const totalPagesCalc = Math.ceil(models?.length ?? 0 / itemsPerPage);
    setTotalPages(totalPagesCalc);

    const indexOfLastModel = page * itemsPerPage;
    const indexOfFirstModel = indexOfLastModel - itemsPerPage;
    const currentModels = models?.slice(indexOfFirstModel, indexOfLastModel);
    setCurrentItems(currentModels ?? []);
  }, [models, page]);


  if (modelsLoading) return <Loader />;
  return (
    <div>
      <body>
        <Helmet>
          <meta
            name="description"
            content={ `This is the description of the ${brand} models` }
          />
          <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
          <meta charSet="UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=5" />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/${brand}` } />

          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/ACCELERA` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/ADVANTA` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/AMERICUS` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/ARROYO` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/BF-GOODRICH` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/BRIDGESTONE` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/CENTARA` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/CONTINENTAL` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/COOPER` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/DEESTONE` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/DELINTE` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/DUNLOP` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/FALKEN` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/FIRESTONE` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/FUZION` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/GALAXY` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/GENERAL` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/GLADIATOR` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/GOODYEAR` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/GTRADIAL` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/HANKOOK` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/HERCULES` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/KELLY` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/KUMHO` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/LANDSAIL` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/MASTERCRAFT` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/MICHELIN` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/NANKANG` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/NEBULA` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/NEOTERRA` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/NEXEN` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/NITTO` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/PIRELLI` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/PROCOMP` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/ROADMASTER` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/SUMITOMO` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/TBB` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/TOYO` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/UNIROYAL` } />
          <meta property="og:url" content={ `https://tirezoneusa.com/Tire-Brands/YOKOHAMA` } />

          <meta
            name="keywords"
            content={ `${brand} models, tires, brand` }
          />
          <title>{ brand } Models Page</title>
          { currentItems.map((model, index) => (
            <meta
              key={ index }
              name="description"
              content={ `This is the description of the ${model.Description}` }
            />
          )) }
        </Helmet>
        <div className="boxed_wrapper" style={ { paddingTop: "10px" } }>
          {/* <PageTitle title={"Models"} /> */ }
          <section className="shop-page-section p_relative">
            <div className="auto-container" style={ { padding: "10px", display: 'flex', justifyContent: "center", alignItems: "center" } }>
              <div className="row clearfix" style={ {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "20px",
                width: "100%",
                padding: "20px"
              } }>
                { ((!models || models.length === 0)) && (
                  <div style={ { display: "flex", flexDirection: "row", justifyContent: "center", width: "70%" } }>
                    <EmptyShop />
                  </div>
                ) }
                { (models && models.length !== 0) && (
                  <Grid container spacing={ 3 } style={ { padding: 20 } }>
                    <Grid item xs={ 12 }>
                      <div className="text">
                        <p className="fs_16 font_family_poppins">
                          { `Showing ` }
                          <span className="color_black">
                            { 1 } – { models?.length }
                          </span>
                          { ` of ` }
                          <span className="color_black">
                            { models?.length ?? 0 }
                          </span> { ` Results` }
                        </p>
                      </div>
                    </Grid>

                    { models.map((model, index) => (
                      <Grid item xs={ 12 } md={ 6 } key={ index }>
                        <ModelsCard selectedBrand={ brand } model={ model } searchInput={ searchInput } setSearchInput={ setSearchInput } />
                      </Grid>
                    )) }
                  </Grid>
                ) }
              </div>
            </div>
          </section>
        </div>
      </body>
    </div>
  )
}

export default ModelsPage;
