import { useState } from "react";
import { getToken } from "../services/GetToken";
import { GetSignUp } from "../services/GetSignup";
import { useDispatch } from "react-redux";
import { useLogin } from "./useLogin";

export const useSignup = () => {
  const [signupRes, setRes] = useState("");
  const [signupLoading, setLoading] = useState(false);
  const [signupError, setError] = useState<string | null>(null);
  const { login, res, loading, error, userInfo } = useLogin();


  const dispatch = useDispatch();
  const signup = async (body: any, isCheckout: boolean) => {
    try {
      setLoading(true);

      const apiResponse = await GetSignUp( body);
      if (apiResponse?.Success === 0) {
        setError(apiResponse.Message);
      } else {
        const user = apiResponse?.Data
        const loginData = {
          eMail: body.eMail,
          password: body.password
        }
        // console.log("isCheckout", isCheckout);
        const loginUser = await login(loginData, isCheckout);
        setRes(apiResponse.Message);
        return loginUser?.id;
      }
    } catch (error) {
      setError("Error Signing in");
    } finally {
      setLoading(false);
    }
  };

  return { signup, signupRes, signupLoading, signupError };
};
