// apiService.ts

import axios, { AxiosRequestConfig } from 'axios';
import { Endpoints } from '../constants/Endpoints';

export const getToken = async () => {
  
  try {
    
    const response:any = await axios.get(Endpoints.GetToken);
    
        // Check for unauthorized error
        if (response.data.Success === 0 && response.data.ErrorCode === 1) {
          // Handle unauthorized error, for example, redirect to login page
          console.error('Un expected error.');
          // You can redirect the user to the login page or show a login modal
          // For example, uncomment the line below to redirect to the login page
          // window.location.href = '/login';
          throw new Error('Un expected error');
        }
        
      localStorage.setItem('token',response.data)
      return response.data;
    
    
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
