import { useEffect, useState } from "react";
import { GetUserSalesOrderDetails } from "../services/GetUserSalesOrderDetails";
import { OrderDetails, OrderDetailsApiResponse } from "../models/app";

export const useGetUserSalesOrderDetails = (OrderId:string) => {
  const [res, setRes] = useState<OrderDetailsApiResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [orderDetails, setOrderDetails] = useState<OrderDetails[]>([]);

  useEffect(() => {
    // console.log("orderId: ", OrderId);

    const fetchOrderDetails = async () => {
      await getOrderDetails(OrderId ?? "");
    };

    fetchOrderDetails();
  }, []);
  
  const getOrderDetails = async (OrderId:string)  => {
    try {
      setLoading(true);

      const apiResponse = await GetUserSalesOrderDetails( OrderId );

      if (apiResponse?.Success === 0) {
        setError(apiResponse.Message);
      } else {
        setRes(apiResponse.Data);
        const orders = apiResponse?.Data?.salesOrderLines??[];
        // console.log(apiResponse.Data);
        
        setOrderDetails(orders);
    }
} catch (error: any) {
    setError("Error getting data");
} finally {
        setLoading(false);
    }
  };

  return { res, loading, error, orderDetails};
};
