import React from "react";
import { Helmet } from "react-helmet";
import useIsMobile from "../../hooks/useIsMobile";
import { Location } from "../../models/app";
import ReactHtmlParser from 'react-html-parser';

// import {
//   GoogleMap,

//   Marker,
//   useLoadScript,
// } from "@react-google-maps/api";
// import { CircularProgress } from "@mui/material";
interface Props{
  location: Location;

}
const LocationsPage: React.FC<Props> = ({location}) => {
  // const locations = useLocation();
  // const location = locations?.state?.item;

  // const mapStyles = {
  //   height: "400px",
  //   width: "100%",
  // };
  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_API!,
  // });

  // const defaultCenter = {
  //   lat: parseFloat(location?.latitude),
  //   lng: parseFloat(location?.longitude),
  // };
  // const parseHTML = (htmlString: string) => {
  //   return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  // };
  const {isMobile} = useIsMobile();
  return (
    <div>
      <Helmet>
        <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'/>
        <meta charSet="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5"/>
        <meta name="format-detection" content="telephone=no"/>
        <title>Location {location?.displayName} - Tire Zone</title>
        
        <meta property="og:locale" content="en_US"/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content={`Location ${location?.displayName} - Tire Zone`}/>
        <meta property="og:url" content="https://tirezoneusa.com/location"/>
        <meta property="og:site_name" content="Tire Zone"/>
        <meta property="article:publisher" content="https://www.facebook.com/pages/Tire-Zone/167358669946020"/>
        <meta property="article:modified_time" content="2022-12-03T07:46:32+00:00"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:site" content="@tirezoneusa"/>
        <meta name="twitter:label1" content="Est. reading time"/>
        <meta name="twitter:data1" content="1 minute"/>
      </Helmet>
      <div>
        <div
          className="boxed_wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "10vw",
            paddingRight: "10vw",
            paddingTop: "10px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              alignItems: "flex-start",
            }}
          >
            <a href={`tel:${location.phoneNo}`} style={{ fontSize: "25px" }}>
              Phone Number: {location.phoneNo}
            </a>
            <p>Our Address:</p>
            <p style={{ fontWeight: "bold" }}>{location.address}</p>
            {/* <HTMLRenderer htmlContent={location.openingHours} /> */}
            {location?.openingHours && ReactHtmlParser(location.openingHours)}
          </div>

          <div style={{ paddingBottom: "10rem" }}>
            <div>
            {location?.embed && ReactHtmlParser(isMobile ? location?.embed :location?.embed.replace("width=\"300\" height=\"200\"","width=\"700\" height=\"400\""))}
            </div>
            {/* {!isLoaded ? (
              <CircularProgress />
            ) : loadError ? (
              <h1>Cannot load maps</h1>
            ) : (
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={15}
                center={{
                  lat: parseFloat(location.latitude),
                  lng: parseFloat(location.longitude),
                }}
                onClick={(event) => {
                  // const lat = event?.latLng?.lat();
                  // const lng = event?.latLng?.lng();
                  const url = `https://www.google.com/maps/search/?api=1&query=${
                    "Tirezone " + location.name + " " + location.address
                  }`;
                  window.open(url, "_blank");
                }}
              >
                <Marker
                  position={{
                    lat: parseFloat(location.latitude),
                    lng: parseFloat(location.longitude),
                  }}
                  title={"Tirezone " + location.displayName}
                />
              </GoogleMap>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationsPage;
