import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <div>
            <h1>Privacy Policy</h1>
            <p>This is the privacy policy for the Tirezone website.</p>
            <h2>Information We Collect</h2>
            <p>Here, you can describe the types of information you collect from users, such as personal information, cookies, or usage data.</p>
            
            <h2>How We Use the Information</h2>
            <p>Explain how you use the collected information, such as for improving the website, providing personalized experiences, or for marketing purposes.</p>
            
            <h2>Information Sharing and Disclosure</h2>
            <p>Describe how you share or disclose the collected information, such as with third-party service providers or for legal purposes.</p>
            
            <h2>Security</h2>
            <p>Explain the security measures you have in place to protect the collected information, such as encryption or access controls.</p>
            
            <h2>Changes to This Privacy Policy</h2>
            <p>Inform users that the privacy policy may be updated and how they will be notified of any changes.</p>
            
            <h2>Contact Us</h2>
            <p>Provide contact information for users to reach out with any questions or concerns regarding the privacy policy.</p>
            
            <h1>Terms of Service</h1>
            <p>This is the Terms of Service for the Tirezone website.</p>
            {/* Add your terms of service content here */}
            <h2>Terms of Service</h2>
            <p>Here, you can provide the terms of service for the Tirezone website.</p>
            <p>Include information about the user's responsibilities, prohibited activities, intellectual property rights, and any disclaimers or limitations of liability.</p>
            <p>Make sure to consult with a legal professional to ensure your terms of service comply with applicable laws and regulations.</p>
        </div>
    );
};

export default PrivacyPolicy;