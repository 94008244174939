// apiService.ts

import  { AxiosRequestConfig } from 'axios';
import axios from '../helpers/axiosInterceptor';
import { Endpoints } from '../constants/Endpoints';
import { SearchBody } from '../models/app';

export const getProducts = async (body: SearchBody[], UserSystemId:string, isRear: boolean) => {
  
  // if(!isRear){
    try {
      const config: AxiosRequestConfig = {
        headers: {
          // Authorization: `Bearer ${token}`,
          'User-SystemId': UserSystemId&&UserSystemId.length!==0?UserSystemId:`d16ad8e9-0c5c-ee11-8df4-6045bde98c3d`,
          'Content-Type': 'application/json',
        },
      };
  
      const response = await axios.post(Endpoints.GetProducts, body, config);
          // Check for unauthorized error
          if (response.data.Success === 0 && response.data.ErrorCode === 1) {
            // Handle unauthorized error, for example, redirect to login page
            console.error('Unauthorized access. Redirecting to login page.');
            // You can redirect the user to the login page or show a login modal
            // For example, uncomment the line below to redirect to the login page
            // window.location.href = '/login';
            throw new Error('Unauthorized access');
          }
        return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  // }
  // else{
  //   try {
  //     const config: AxiosRequestConfig = {
  //       headers: {
  //         'user-systemid': UserSystemId&&UserSystemId.length!==0?UserSystemId:`d16ad8e9-0c5c-ee11-8df4-6045bde98c3d`,
  //         'Content-Type': 'application/json',
  //       },
  //     };
  
  //     const response = await axios.post(Endpoints.GetProductsRear, body, config);
  //         // Check for unauthorized error
  //         if (response.data.Success === 0 && response.data.ErrorCode === 1) {
  //           // Handle unauthorized error, for example, redirect to login page
  //           console.error('Unauthorized access. Redirecting to login page.');
  //           // You can redirect the user to the login page or show a login modal
  //           // For example, uncomment the line below to redirect to the login page
  //           // window.location.href = '/login';
  //           throw new Error('Unauthorized access');
  //         }
  //       return response.data;
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     throw error;
  //   }

  // }

};
