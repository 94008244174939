import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Box, TableCell } from "@mui/material";
import Loader from "../../components/UI/loader/Loader";
import { useGetUserInvoices } from "../../hooks/useGetUserInvoices";
import { RootState } from "../../store/ducks";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";

// function descendingComparator(a: any, b: any, orderBy: any) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function stableSort(array: any, comparator: any) {
//   const stabilizedThis = array.map((el: any, index: any) => [el, index]);
//   stabilizedThis.sort((a: any, b: any) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el: any) => el[0]);
// }

// function getComparator(order: "asc" | "desc", orderBy: string) {
//   return order === "desc"
//     ? (a: any, b: any) => compare(a, b, orderBy)
//     : (a: any, b: any) => -compare(a, b, orderBy);
// }

// function compare(a: any, b: any, orderBy: string) {
//   if (orderBy === "InvoiceDate") {
//     // Assuming InvoiceDate is in a format that can be parsed to a date
//     return Date.parse(a[orderBy]) - Date.parse(b[orderBy]);
//   } else {
//     // Handle other types of sorting (e.g., strings, numbers) as needed
//     if (a[orderBy] < b[orderBy]) {
//       return -1;
//     }
//     if (a[orderBy] > b[orderBy]) {
//       return 1;
//     }
//   }
//   return 0;
// }
export default function UserInvoicesPage() {
  const userId = useSelector((state: RootState) => state.store?.userSystemId);
  const {  loading, invoices } = useGetUserInvoices(userId);
  // const [order, setOrder] = useState<"asc" | "desc">("asc");
  // const [orderBy, setOrderBy] = useState<string>("dateOrdered");
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);

  // const handleRequestSort = (property: keyof Invoice) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  // const handleChangePage = (event: any, newPage: any) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event: any) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  function formatDate(inputDate: any) {
    const date = new Date(inputDate);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  // const createSortHandler = (property: keyof Invoice) => () => {
  //   handleRequestSort(property);
  // };
  const columns: GridColDef<any>[] = [
    {
      field: "ViewDetails",
      headerName: "View Details",
      width: 200,
      renderCell: (params) => (
        <TableCell>
          <Link to={`/invoiceDetails/${params.row.Id}`}>View Details</Link>
        </TableCell>
      ),
      filterable:false,
      sortable:false,
    },
    {
      field: "OrderNumber",
      headerName: "Order Number",
      width: 200,
      
    },
    {
      field: "InvoiceDate",
      headerName: "Date Ordered",
      width: 200,
      
      valueGetter: (value, row) => formatDate(row.InvoiceDate),
    },
    {
      field: "ExternalDocumentNumber",
      headerName: "PO Number",
      width: 200,
      
    },
    {
      field: "TotalAmountIncludingTax",
      headerName: "Total Price",
      width: 200,
      
    },
  ];

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Orders</title>
        <meta name="description" content="User Orders Page" />
        <meta name="keywords" content="User Orders Page" />
        <meta name="author" content="TireZones" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
        {invoices &&
          invoices.map((invoice: any) => (
            <meta
              key={invoice.id}
              name="invoice"
              content={`Order Number: ${
                invoice.OrderNumber
              }, Date: ${formatDate(invoice.InvoiceDate)}, Status: ${
                invoice.Status
              }, Total: ${invoice.TotalAmountIncludingTax}`}
            />
          ))}
      </Helmet>
      {loading && <Loader />}
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={invoices}
          getRowId={(row: any) => row.Id}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
            sorting: {
              sortModel: [{ field: "OrderNumber", sort: "desc" }],
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          
          // disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          
          pageSizeOptions={[5]}

          disableRowSelectionOnClick
        />
      </Box>

      {!loading && (
        <></>
        // <TableContainer component={Paper} style={{padding: "25px"}}>
        //   <Table>
        //     <TableHead>
        //       <TableRow>
        //         <TableCell>
        //           View Details
        //         </TableCell>
        //         <TableCell>
        //           Order Number
        //         </TableCell>

        //         <TableCell
        //           sortDirection={orderBy === 'dateOrdered' ? order : false}
        //         >
        //           <TableSortLabel
        //               active={orderBy === 'dateOrdered'}
        //               direction={orderBy === 'dateOrdered' ? order : 'asc'}
        //               onClick={createSortHandler('InvoiceDate')}
        //           >
        //             Date Ordered
        //           </TableSortLabel>
        //         </TableCell>
        //         <TableCell>PO Number</TableCell>
        //         {/* <TableCell>Status</TableCell> */}
        //         <TableCell>Total Price</TableCell>
        //       </TableRow>
        //     </TableHead>
        //     <TableBody>
        //       {stableSort(invoices, getComparator(order, orderBy))
        //         .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        //         .map((invoice:Invoice, index:number) => (
        //           <TableRow key={index}>
        // <TableCell>
        //   <Link to={`/invoiceDetails/${invoice.Id}`}>View Details</Link>
        // </TableCell>
        //             <TableCell>{invoice.OrderNumber}</TableCell>
        //             <TableCell>{formatDate(invoice.InvoiceDate)}</TableCell>
        //             {/* <TableCell>{invoice.Status?? ""}</TableCell> */}
        //             {/* <TableCell>{invoice.Status?? ""}</TableCell> */}
        //             <TableCell>${invoice.TotalAmountIncludingTax}</TableCell>
        //           </TableRow>
        //         ))}
        //     </TableBody>
        //   </Table>
        //   <TablePagination
        //     rowsPerPageOptions={[10, 15, 30]}
        //     component="div"
        //     count={invoices.length}
        //     rowsPerPage={rowsPerPage}
        //     page={page}
        //     onPageChange={handleChangePage}
        //     onRowsPerPageChange={handleChangeRowsPerPage}
        //   />
        // </TableContainer>
      )}
    </div>
  );
}
