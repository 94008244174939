import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { Button, CssBaseline, Dialog, DialogContent, Grid, IconButton, createTheme, styled } from '@mui/material';
import HomePageFilters from '../HomePageFilters/HomePageFilters';

const NewSearch = () => {

    const [newSearch, setNewSearch] = useState(false);

    const theme = createTheme({});
    
    const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
        'overflowY': 'auto',
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '10px',
          backgroundColor: '#EEE',
          marginBottom: '1rem',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          backgroundColor: '#E7131A',
          '&:hover': {
            backgroundColor: '#D60209',
          },
        },
      }));


  return (
    <>

        <div className="select-btn" style={{ cursor: "pointer", marginRight: "15px"}}>
            <Button
              fullWidth
              variant="contained"
              startIcon={<SearchIcon />}
              style={{
                  backgroundColor: "#E7131A",
                  fontSize: "15px",
                  padding: "15px 15px",
                  borderRadius: 0,
              }}
              type="button"
              onClick={()=> setNewSearch(!newSearch)}
            >
              Tires Search
            </Button>
        </div>

        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Dialog 
                open={newSearch} 
                onClose={()=>setNewSearch(false)} 
                fullWidth 
                maxWidth="lg"
                PaperProps={{
                sx: { maxHeight: '95%', overflowY: 'auto' },
                className: 'custom-scrollbar' // Applies the custom scrollbar styles
                }}
            >
              <Grid container spacing={0} p={0} padding={0}>
                <Grid sx={{display:'flex' , justifyContent:'flex-end', alignItems:'flex-end',width:'100%'}}>

                <IconButton
                    onClick={()=>setNewSearch(false)}
                    sx={{
                      size: 'large',
                      color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon fontSize='large'/>
                </IconButton>
                        </Grid>
                <CustomDialogContent sx={{pt:0,px:2}}>
                
                    {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}> */}
                    <HomePageFilters border={false}/>
                    {/* </Grid> */}
                
                </CustomDialogContent>
                      </Grid>
            </Dialog>
        </ThemeProvider>
    </>
  );
};


export default NewSearch;