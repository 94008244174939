import axios from "axios";
import { getToken } from "../services/GetToken";
import { Endpoints } from "../constants/Endpoints";

export const decodedToken = (token: string) => {
	if (!token) return null;
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
	return JSON.parse(jsonPayload);
};

const MAX_RETRY_COUNT = 1;
let retryCount = 0;

let ongoingRequests: any = [];
let throttleTimer: any;

const axiosInstance = axios.create({
  // baseURL: "https://devbackoffice.scofolio.com/",
  baseURL: "https://api.tirezoneusa.com/",
  timeout: 100000,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("token") || "";
    const exp = decodedToken(token)?.exp
    const tokenValid = Date.now() <= (exp * 1000);
    
    if (token && token.length > 0 && tokenValid) {
      
      config.headers["Authorization"] = "Bearer " + token;
      return config;
    } else {
      const res = await axios.get(Endpoints.GetToken);
      localStorage.setItem("token", res.data);

      config.headers["Authorization"] = `Bearer ${res.data}`;
      return config;
    }
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

function areRequestsEqual(request1: any, request2: any) {
  const req1Data =
    typeof request1.data === "object"
      ? JSON.stringify(request1.data)
      : request1.data;
  const req2Data =
    typeof request2.data === "object"
      ? JSON.stringify(request2.data)
      : request2.data;

  return (
    request1.method === request2.method &&
    request1.url === request2.url &&
    JSON.stringify(request1.params) === JSON.stringify(request2.params) &&
    req1Data === req2Data
  );
}
// axiosInstance.interceptors.request.use((config) => {
//   const isDuplicateRequest = ongoingRequests.some((request: any) =>
//     areRequestsEqual(request, config)
//   );

//   if (isDuplicateRequest) {
//     return Promise.reject("Duplicate request");
//   }

//   ongoingRequests.push(config);

//   if (!throttleTimer) {
//     throttleTimer = setTimeout(() => {
//       ongoingRequests = [];
//       throttleTimer = null;
//     }, 500); // 1 second
//   }

//   return config;
// });

axiosInstance.interceptors.response.use(
  (response) => {
    // Handle successful responses
    retryCount = 0;
    return response;
  },
  async (error) => {
    // Handle error responses
    const originalRequest = error.config;

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      if (error.response.status === 401 || error.response.status === 403)
        {
          if (retryCount < MAX_RETRY_COUNT)
          {
  
            let res = await getToken();
  
            originalRequest.headers.Authorization = `Bearer ${res.data}`;
  
            retryCount++;
            // Retry the original request
            return axiosInstance(originalRequest);
          }
          else{
            window.location.reload();
          }
        } else
        {
          retryCount = 0
        }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return Promise.reject(error);
  }
);

export default axiosInstance;
