import { useEffect, useState } from "react";
import { SearchOptions } from "../../constants/JsonObjects";
import useProfileOptions from "../../hooks/useProfileOptions";
import useWheelOptions from "../../hooks/useWheelOptions";
import DropDown from "../dropdownComponent/DropDown";
import useSeasonOptions from "../../hooks/useSeasonOptions";
// import useSpeedRatingOptions from "../../hooks/useSpeedRatingOptions";
// import useLoadIndexOptions from "../../hooks/useLoadIndexOptions";
import useHomeBanner from "../../hooks/useHomeBanner";
import SearchIcon from "@mui/icons-material/Search";
import { NewValidate, SearchInput } from "../../models/app";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useSearchOptions } from "../../hooks/useSearchOptions";
import Alert from "../UI/alert/Alert";
import useIsMobile from "../../hooks/useIsMobile";
type SelectedFilters = Record<string, string>;
// const instructionsImage = require('../../assets/images/tire size location white background.png');
const instructionsImage = require("../../assets/images/tire size location white background.webp");

const HomePageFilters = ({ border = true }: any) => {
  const [errorAlert, setErrorAlert] = useState(false);
  const { searchOptions } = useSearchOptions();

  const {
    profiles,
    profilesRear,
    profilesLoading,
    profileError,
    profileErrorRear,
    fetchProfileData,
    setProfilesError,
    setProfilesErrorRear,
  } = useProfileOptions(setErrorAlert);

  const {
    wheels,
    wheelsRear,
    wheelsLoading,
    wheelsError,
    wheelsErrorRear,
    fetchWheelsData,
    setWheelsError,
    setWheelsErrorRear,
  } = useWheelOptions(setErrorAlert);

  const { seasons, seasonsLoading, fetchSeasonData } = useSeasonOptions();

  const {
    HandleSearchClick,
    selectedLocation,
    userSystemId,
    clickError,
    setClickError,
  } = useHomeBanner(setErrorAlert);

  const [searchType, setSearchType] = useState("By Tire Size");
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [differentRearSize, setDifferentRearSize] = useState(false);
  const [differentRearSizeRawSize, setDifferentRearSizeRawSize] =
    useState(false);
  const [searchInput, setSearchInput] = useState<SearchInput>({
    Width: "",
    Ratio: "",
    Wheel: "",
    Season: "",
    speedRating: "",
    loadIndex: "",
    RawSize: "",
  });
  const [searchInputRear, setSearchInputRear] = useState<SearchInput>({
    Width: "",
    Ratio: "",
    Wheel: "",
    Season: "",
    speedRating: "",
    loadIndex: "",
    RawSize: "",
  });
  const [searchInputValidate, setSearchInputValidate] = useState({
    Width: false,
    Ratio: false,
    Wheel: false,
    RawSize: false,
  });
  const [searchInputValidateRear, setSearchInputValidateRear] = useState({
    Width: false,
    Ratio: false,
    Wheel: false,
    RawSize: false,
  });
  const [filtersListOpen, setFiltersListOpen] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({});
  const [selectedFiltersRear, setSelectedFiltersRear] =
    useState<SelectedFilters>({});

  //   const handleSearchTypeChange = (event: any) => {
  //     setSearchType(event.target.value);
  //   };
  //   const handleLocationChange = (event: any) => {
  //     setSelectedLocation(event.target.value);
  //     localStorage.setItem('location', JSON.stringify(event.target.value));
  //     localStorage.setItem('selectedLocation', (JSON.stringify(event.target.value)));
  //     localStorage.setItem('userSystemId', event.target.value.guestCutomerId);
  //   };

  const filterMappings: any = {
    Width: {
      state: searchInput,
      stateRear: searchInputRear,
      data: searchOptions?.Data ? searchOptions?.Data.widthOptions : [],
      dataRear: searchOptions?.Data ? searchOptions?.Data.widthOptions : [],
      loading: false,
      fetchFunction: fetchProfileData,
    },
    Profile: {
      state: searchInput,
      stateRear: searchInputRear,
      data: profiles ?? [],
      dataRear: profilesRear ?? [],
      loading: profilesLoading,
      fetchFunction: fetchWheelsData,
    },
    "Wheel Size": {
      state: searchInput,
      stateRear: searchInputRear,
      data: wheels ?? [],
      dataRear: wheelsRear ?? [],
      loading: wheelsLoading,
      fetchFunction: fetchSeasonData,
    },
    Season: {
      state: searchInput,
      data: seasons ?? [],
      dataRear: seasons ?? [],
      loading: seasonsLoading,
      fetchFunction: () => {},
    },
  };

  const handleFilterChange = async (
    filterName: string,
    value: string,
    selectedValue: string
  ) => {
    const { state, stateRear, fetchFunction } = filterMappings[filterName];

    const newState = { ...state };
    const newValidate: NewValidate = { ...searchInputValidate };
    const newValidateRear: NewValidate = { ...searchInputValidateRear };
    const newStateRear = { ...stateRear };
    const isRear = value === "Rear";

    if (isRear) {
      const newName =
        filterName.toLowerCase() === "profile"
          ? "Ratio"
          : filterName.toLowerCase() === "wheel size"
          ? "Wheel"
          : filterName;
      newStateRear[newName] = selectedValue !== null ? selectedValue : "";
      newValidateRear[newName] = true;
      // console.log(newValidateRear);
      setSearchInputValidateRear(newValidateRear);
      setSearchInputRear(newStateRear);
    } else {
      const newName =
        filterName.toLowerCase() === "profile"
          ? "Ratio"
          : filterName.toLowerCase() === "wheel size"
          ? "Wheel"
          : filterName;
      newState[newName] = selectedValue !== null ? selectedValue : "";
      newValidate[newName] = true;
      // console.log(newName, newValidate);
      setSearchInputValidate(newValidate);
      setSearchInput(newState);
    }

    if (fetchFunction) {
      await fetchFunction(
        searchOptions,
        selectedValue !== null ? selectedValue : "",
        value
      );
    }

    if (isRear) {
      setSelectedFiltersRear((prevFilters) => ({
        ...prevFilters,
        [filterName]: selectedValue !== null ? selectedValue : "",
      }));
    } else {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: selectedValue !== null ? selectedValue : "",
      }));
    }
  };

  const checkLoading = (name: string, loadingStates: any) => {
    return !!loadingStates[name].loading;
  };

  const checkData = (name: string, dataSets: any, keyword: any) => {
    return (
      dataSets[name] &&
      dataSets[name][keyword] &&
      dataSets[name][keyword].length > 0
    );
  };

  const getFilterData = (name: string, dataSets: any, keyword: any) => {
    return (
      dataSets[name][keyword]?.map((item: any) =>
        name === "Season" ? item.code : item.data ? item.data : item
      ) || []
    );
  };

  // const dataSets = {
  //   Profile: profiles,
  //   "Wheel Size": wheels,
  //   Season: seasons,
  //   // "Speed Rating": speedRating,
  //   // "Load Index": loadIndex,
  // };

  // const dataSetsRear = {
  //   Profile: profilesRear,
  //   "Wheel Size": wheelsRear,
  //   // "Speed Rating": speedRatingRear,
  //   // "Load Index": loadIndexRear,
  // };

  //   const handleToggle = (event: any, newValue: string) => {
  //     if (newValue){
  //       setSelected(newValue);
  //     }
  //   };

  const [value, setValue] = useState("");
  const [valueRear, setValueRear] = useState("");

  const handleChange = (event: any, isRear: string) => {
    if (isRear === "rear") {
      setSearchInputRear({
        Width: "",
        Ratio: "",
        Wheel: "",
        Season: "",
        speedRating: "",
        loadIndex: "",
        RawSize: event.target.value,
      });
      const newValidateRear: NewValidate = { ...searchInputValidateRear };
      newValidateRear.RawSize = true;
      setSearchInputValidateRear(newValidateRear);
      setValueRear(event.target.value);
    } else {
      setSearchInput({
        Width: "",
        Ratio: "",
        Wheel: "",
        Season: "",
        speedRating: "",
        loadIndex: "",
        RawSize: event.target.value,
      });
      const newValidate: NewValidate = { ...searchInputValidate };
      newValidate.RawSize = true;
      setSearchInputValidate(newValidate);
      setValue(event.target.value);
    }
  };

  useEffect(() => {
    if (
      profileError ||
      profileErrorRear ||
      wheelsError ||
      wheelsErrorRear ||
      clickError
    ) {
      // console.log(errorAlert);
      setErrorAlert(true);
    }
  }, [
    profileError,
    profileErrorRear,
    wheelsError,
    wheelsErrorRear,
    clickError,
  ]);

  useEffect(() => {
    if (errorAlert === false) {
      if (profileError) {
        setProfilesError(null);
      }
      if (profileErrorRear) {
        setProfilesErrorRear(null);
      }
      if (wheelsError) {
        setWheelsError(null);
      }
      if (wheelsErrorRear) {
        setWheelsErrorRear(null);
      }
      if (clickError) {
        setClickError(null);
      }
    }
  }, [errorAlert]);
  const { isMobile } = useIsMobile();

  // if (loading) return <Loader />;
  return (
    <>
      <div
        className="banner-right-container"
        style={{
          border: isMobile && !border ? "none" : "1px solid black",
          borderRadius: "10px",
        }}
      >
        <Grid container>
          <Grid
            container
            spacing={2}
            component="form"
            onSubmit={(event) => {
              event.preventDefault();
              HandleSearchClick(
                selectedLocation,
                setLocationModalOpen,
                searchInput,
                searchInputRear,
                userSystemId ?? "",
                searchInputValidate,
                searchInputValidateRear,
                differentRearSize,
                differentRearSizeRawSize,
                searchType
              );
              // console.log(profileError , wheelsError , clickError);
              if (profileError || wheelsError || clickError) {
                setErrorAlert(true);
                event.preventDefault();
              }
            }}
            autoComplete="off"
          >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              {/* <div className="banner-right-container-top">
            <div className="banner-right-car">
                <img
                src={require("../../assets/images/icons/car-1.png")}
                alt="car"
                />
            </div>
            <div className="banner-right-title">
                <h6
                style={{
                    textDecoration: "underline",
                    color: "var(--theme-color)",
                    cursor: "pointer",
                }}
                onClick={() => setLocationModalOpen(true)}
                > 
                {selectedLocation 
                    ? selectedLocation.name
                    : " Choose Your Location"}
                
                </h6>
                <ChangeLocationModal
                open={locationModalOpen}
                setOpen={setLocationModalOpen}
                locations={locations}
                selectedLocation={selectedLocation}
                handleLocationChange={handleLocationChange}
                setUserSystemId={setUserSystemId}
                />
            </div>
            </div> */}

              <Grid container>
                {
                  <Grid
                    item
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={
                      12
                    } /*sx={{border: "1px solid black", borderRadius: "10px"}}*/
                  >
                    <Typography
                      component={"h1"}
                      sx={{
                        typography: { md: "h5" },
                        color: "black",
                        fontWeight: "bold",
                        marginBottom: "10px",
                        borderBottom: "2px solid black",
                      }}
                    >
                      Search By Tire Size
                    </Typography>
                    <div>
                      <h2
                        style={{
                          textDecoration: "underline",
                          color: "#E7131A",
                          cursor: "pointer",
                          fontSize: "11px",
                          fontWeight: "normal",
                        }}
                        onClick={() => setDifferentRearSize(!differentRearSize)}
                      >
                        {differentRearSize
                          ? "HIDE REAR SIZE"
                          : "ADD DIFFERENT REAR SIZE"}
                      </h2>
                      <br />
                      <Grid container>
                        {/* {(profileError || wheelsError || clickError) && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h6 style={{ color: "black", fontSize: "13px"}}>
                    {profileError ?? wheelsError ?? clickError}
                    </h6>
                </Grid>
                )} */}

                        {/* {selected === "Front" && ( */}
                        <Grid
                          item
                          xl={differentRearSize ? 0 : 1.5}
                          lg={differentRearSize ? 0 : 1.5}
                          md={differentRearSize ? 0 : 1.5}
                          sm={0}
                          xs={0}
                        ></Grid>
                        <Grid
                          item
                          xl={differentRearSize ? 6 : 9}
                          lg={differentRearSize ? 6 : 9}
                          md={differentRearSize ? 6 : 9}
                          sm={12}
                          xs={12}
                        >
                          <div className="select-container">
                            <h3
                              style={{
                                color: "black",
                                fontSize: "18px",
                                marginBottom: "5px",
                              }}
                            >
                              {differentRearSize ? `Front Size` : `Tire Size`}
                            </h3>
                            {SearchOptions.find(
                              (item) => item.name === searchType
                            )
                              ?.filterOptions.filter(
                                (item) => item.name !== "Season"
                              )
                              .map((item, index) => (
                                <DropDown
                                  key={index + 20}
                                  title={item.name}
                                  data={
                                    getFilterData(
                                      item.name,
                                      filterMappings,
                                      "data"
                                    ) ?? []
                                  }
                                  selectedValue={
                                    selectedFilters[item.name] || ""
                                  }
                                  handleValueChange={(value: any) =>
                                    handleFilterChange(
                                      item.name,
                                      "Front",
                                      value
                                    )
                                  }
                                  listOpen={filtersListOpen}
                                  setListOpen={
                                    checkData(item.name, filterMappings, "data")
                                      ? setFiltersListOpen
                                      : () => {}
                                  }
                                  isLoading={
                                    checkLoading(item.name, filterMappings) ||
                                    !checkData(
                                      item.name,
                                      filterMappings,
                                      "data"
                                    )
                                  }
                                />
                              ))}
                          </div>
                        </Grid>
                        {/* )} */}
                        {/* {selected === "Rear" && ( */}
                        {differentRearSize && (
                          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div
                              className="select-container"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "stretch",
                              }}
                            >
                              <h3
                                style={{
                                  color: "black",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Rear Size
                              </h3>
                              {/* {(profileErrorRear || wheelsErrorRear)?<h6 style={{color: "black", fontSize: "13px", marginBottom: "5px"}}>{profileErrorRear??wheelsErrorRear}</h6>:<></>} */}
                              {SearchOptions.find(
                                (item) => item.name === searchType
                              )
                                ?.filterOptions.filter(
                                  (item) => item.name !== "Season"
                                )
                                .map((item, index) => (
                                  <DropDown
                                    key={index + 20}
                                    title={item.name}
                                    data={
                                      getFilterData(
                                        item.name,
                                        filterMappings,
                                        "dataRear"
                                      ) ?? []
                                    }
                                    selectedValue={
                                      selectedFiltersRear[item.name] || ""
                                    }
                                    handleValueChange={(value: any) =>
                                      handleFilterChange(
                                        item.name,
                                        "Rear",
                                        value
                                      )
                                    }
                                    // selectStyles={}
                                    listOpen={filtersListOpen}
                                    setListOpen={
                                      checkData(
                                        item.name,
                                        filterMappings,
                                        "dataRear"
                                      )
                                        ? setFiltersListOpen
                                        : () => {}
                                    }
                                    isLoading={
                                      checkLoading(item.name, filterMappings) ||
                                      !checkData(
                                        item.name,
                                        filterMappings,
                                        "dataRear"
                                      )
                                    }
                                  />
                                ))}
                            </div>
                          </Grid>
                        )}
                        {/* )} */}
                      </Grid>
                    </div>
                  </Grid>
                }

                <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                  <Typography
                    sx={{
                      typography: { md: "h3", xs: "h5" },
                      fontWeight: 700,
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    OR
                  </Typography>
                </Grid>

                {
                  // searchType === "By Raw Size" && (
                  <Grid
                    item
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={
                      12
                    } /*sx={{border: "1px solid black", borderRadius: "10px"}}*/
                  >
                    <Typography
                      component={"h1"}
                      sx={{
                        typography: { md: "h5" },
                        color: "black",
                        fontWeight: "bold",
                        marginBottom: "10px",
                        borderBottom: "2px solid black",
                      }}
                    >
                      Search By Raw Size
                    </Typography>
                    <div>
                      <h2
                        style={{
                          textDecoration: "underline",
                          color: "#E7131A",
                          cursor: "pointer",
                          fontSize: "11px",
                          fontWeight: "normal",
                        }}
                        onClick={() =>
                          setDifferentRearSizeRawSize(!differentRearSizeRawSize)
                        }
                      >
                        {differentRearSizeRawSize
                          ? "HIDE REAR SIZE"
                          : "ADD DIFFERENT REAR SIZE"}
                      </h2>
                      <br />
                      <Grid container spacing={2}>
                        {/* {(profileError || wheelsError || clickError) && (
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <h6 style={{ color: "black", fontSize: "13px", marginBottom: "5px" }}>
                            {profileError ?? wheelsError ?? clickError}
                        </h6>
                        </Grid>
                    )} */}

                        <Grid
                          item
                          xl={differentRearSizeRawSize ? 0 : 1.5}
                          lg={differentRearSizeRawSize ? 0 : 1.5}
                          md={differentRearSizeRawSize ? 0 : 1.5}
                          sm={0}
                          xs={0}
                        ></Grid>
                        <Grid
                          item
                          xl={differentRearSizeRawSize ? 5 : 9}
                          lg={differentRearSizeRawSize ? 5 : 9}
                          md={differentRearSizeRawSize ? 5 : 9}
                          sm={12}
                          xs={12}
                        >
                          <h3
                            style={{
                              color: "black",
                              fontSize: "18px",
                              marginBottom: "5px",
                            }}
                          >
                            {differentRearSizeRawSize
                              ? `Front Size`
                              : `Tire Size`}
                          </h3>
                          <TextField
                            inputProps={{ "aria-required": true }}
                            sx={{
                              "& .MuiInputBase-root": {
                                height: "45px",
                                color: "black",
                                background: "white",
                                borderRadius: "5px",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid black",
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "16px",
                                fontWeight: "500",
                                padding: "0px 20px",
                                lineHeight: "45px",
                              },
                            }}
                            variant="outlined"
                            placeholder="Raw Size"
                            value={value}
                            onChange={(event) => handleChange(event, "front")}
                            fullWidth
                          />
                        </Grid>
                        {/* Conditionally show the rear size field */}
                        {differentRearSizeRawSize && (
                          <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                            <h3
                              style={{
                                color: "black",
                                fontSize: "18px",
                                marginBottom: "5px",
                              }}
                            >
                              Rear Size
                            </h3>
                            <TextField
                              aria-required={true}
                              sx={{
                                "& .MuiInputBase-root": {
                                  height: "45px",
                                  color: "black",
                                  background: "white",
                                  borderRadius: "5px",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid black",
                                },
                                "& .MuiInputBase-input": {
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  padding: "0px 20px",
                                  lineHeight: "45px",
                                },
                              }}
                              variant="outlined"
                              placeholder="Raw Size"
                              value={valueRear}
                              onChange={(event) => handleChange(event, "rear")}
                              fullWidth
                            />
                          </Grid>
                        )}
                      </Grid>
                    </div>
                    <Grid container sx={{ marginTop: "20px" }}>
                      <Grid
                        item
                        xl={1.5}
                        lg={1.5}
                        md={1.5}
                        sm={0}
                        xs={0}
                      ></Grid>
                      <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "black",
                            fontWeight: "bold",
                            textAlign: "center",
                            marginBottom: "10px",
                          }}
                        >
                          Example: 2055516
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xl={1.5}
                        lg={1.5}
                        md={1.5}
                        sm={0}
                        xs={0}
                      ></Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <div
                  className="select-btn"
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={<SearchIcon />}
                    style={{
                      backgroundColor: "#E7131A",
                      fontSize: "0.8rem",
                      padding: "15px 30px",
                      borderRadius: 0,
                    }}
                    type="submit"
                  >
                    Find Your Tires Now
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <img
                src={instructionsImage}
                alt="Banner showing tire sizes with text: 'Find the right tire size for your vehicle.'"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "10px",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>

      {errorAlert &&
        (profileError ||
          profileErrorRear ||
          wheelsError ||
          wheelsErrorRear ||
          clickError) && (
          <Alert
            severity={"error"}
            text={
              clickError ??
              profileError ??
              profileErrorRear ??
              wheelsError ??
              wheelsErrorRear
            }
            setError={setErrorAlert}
          />
        )}
    </>
  );
};

export default HomePageFilters;
