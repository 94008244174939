import { useState } from "react";
import { GetInvoiceDetails } from "../services/GetInvoiceDetails";

export const useGetInvoiceDetails = () => {
  const [res, setRes] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
    const[invoiceDetails, setInvoiceDetails] = useState<any>();

  const getInvoiceDetails = async (customerId: string, invoiceId:string)  => {
    try {
      setLoading(true);

      const apiResponse = await GetInvoiceDetails( customerId, invoiceId);

      if (apiResponse?.Success === 0) {
        setError(apiResponse.Message);
      } else {
        setRes(apiResponse.Message);
        const invoices = apiResponse?.Data
        setInvoiceDetails(invoices);
    }
} catch (error: any) {
    setError("Error getting data");
} finally {
        setLoading(false);
    }
  };

  return { getInvoiceDetails, res, loading, error, invoiceDetails};
};
