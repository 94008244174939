import { useState } from "react";
import { ChangePassword } from "../services/ChangePassword";

export const useChangePassword = () => {
  const [changePasswordres, setRes] = useState("");
  const [changePasswordloading, setLoading] = useState(false);
  const [changePassworderror, setError] = useState<string | null>(null);

  const changePassword = async (body: any) => {
    try {
      setLoading(true);

      // const token = await getToken();
      const apiResponse = await ChangePassword( body);

      if (apiResponse?.Success === 0) {
        setError(apiResponse.Message);
      } else {
        setRes(apiResponse.Message);
        localStorage.clear();
        window.location.href = "/login";
      }
    } catch (error: any) {
      setError("Error Changing Password");
    } finally {
      setLoading(false);
    }
  };

  return {
    changePassword,
    changePasswordres,
    changePasswordloading,
    changePassworderror,
  };
};
