import { useEffect, useState } from "react";
import GetSeasonOptions from "../services/GetSeasonOptions";
import { SearchFiltersApiResponse } from "../models/app";


const useSeasonOptions = () => {
  const [seasons, setSeason] = useState<{code: string; description: string; count: number;}[]>([]);  
  const [seasonsLoading, setLoading] = useState(false);
  const [seasonError, setError] = useState<any>(null);
  
  const fetchSeasonData = async (data:SearchFiltersApiResponse) => {
    try {
      setLoading(true);
      // const data = await GetProfileOptions(width);
      const results = data.Data.seasonOptions;
      if(results.length == 0){
        setError("No Data for this width size");
      }
      else{
        setError(null);
      }
      
      setSeason(results);
      
      setLoading(false);
    } catch (error:any) {
      setError(error);
    }
  };
  
  return {
    seasons,
    seasonsLoading,
    seasonError,
    fetchSeasonData,
  };
};

export default useSeasonOptions;
