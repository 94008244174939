// ducks/app.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartItem, CartState, Location, LoggedinUser } from '../../models/app';


const initialState: CartState = {
  cart: JSON.parse(localStorage.getItem('cart') ?? "[]"),
  discountApplied: JSON.parse(localStorage.getItem('discount') ?? "false"),
  discountPercentage: JSON.parse(localStorage.getItem('discountPercentage') ?? "0"),
  locations: JSON.parse(localStorage.getItem('locationsList') ?? "[]"),
  selectedLocation: JSON.parse(localStorage.getItem('selectedLocation')?? `""`),
  userSystemId: localStorage.getItem('userSystemId') ?? "",
  user: JSON.parse(localStorage.getItem('user')?? `""`),
};

const cartSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<CartItem>) => {
      const existingIndex = state.cart.findIndex((item: CartItem) => item.id === action.payload.id);
      if (existingIndex !== -1) {
        state.cart[existingIndex].quantity += action.payload.quantity;
      } else {
        state.cart.push(action.payload);
      }
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    removeFromCarts: (state, action: PayloadAction<string>) => {
      const index = state.cart.findIndex((item: CartItem) => item.id === action.payload);
      if (index !== -1) {
        state.cart.splice(index, 1);
        localStorage.setItem('cart', JSON.stringify(state.cart));
      }
    },
    clearCart: (state) => {
      state.cart = [];
      localStorage.removeItem('cart');
    },
    editCartItem: (state, action: PayloadAction<{id: string, data: Partial<CartItem>}>) => {
      const { id, data } = action.payload;
      const index = state.cart.findIndex((item: CartItem) => item.id === id);
      if (index !== -1) {
        state.cart[index] = { ...state.cart[index], ...data };
        localStorage.setItem('cart', JSON.stringify(state.cart));
      }
    },
    updateLocations: (state, action: PayloadAction<Location[]>) => {
      state.locations = action.payload;
      localStorage.setItem('locationsList', JSON.stringify(action.payload));
    },
    updateSelectedLocation: (state, action: PayloadAction<Location>) => {
      state.selectedLocation = action.payload;
      localStorage.setItem('selectedLocation', JSON.stringify(action.payload));
    },    
    updateUserSystemId: (state, action: PayloadAction<string>) => {
      state.userSystemId = action.payload;
      localStorage.setItem('userSystemId', action.payload);
    },
    applyDiscount: (state, action: PayloadAction<boolean>) => {
      state.discountApplied = action.payload;
      // console.log("boolean: ", action.payload);
      localStorage.setItem('discount', JSON.stringify(state.discountApplied));
    },
    updateDiscount: (state, action: PayloadAction<number>) => {
      state.discountPercentage = action.payload;
      // console.log("percentage: ", action.payload);
      localStorage.setItem('discountPercentage', JSON.stringify(state.discountPercentage));
    },
    updateUser: (state, action: PayloadAction<LoggedinUser | "guest">) => {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
  },
});

export const { addToCart, removeFromCarts, clearCart, editCartItem, updateLocations, updateSelectedLocation, updateUserSystemId, applyDiscount, updateDiscount, updateUser } = cartSlice.actions;

export default cartSlice.reducer;
