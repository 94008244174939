import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/ducks";

function Loader() {
  const selectedLocation = useSelector(
    (state: RootState) => state?.store?.selectedLocation
  );
  return (
    <>
    
    
    <div className="loader-wrap">
      <div className="preloader">
      <div id="handle-preloader" className="handle-preloader preloader-image home-16" >
          <div className="animation-preloader">
            <div className="spinner"></div>
            <div className="txt-loading">
              <span data-text-preloader="T" className="letters-loading">
                T
              </span>
              <span data-text-preloader="i" className="letters-loading">
                i
              </span>
              <span data-text-preloader="r" className="letters-loading">
                r
              </span>
              <span data-text-preloader="e" className="letters-loading">
                e
              </span>
              <span data-text-preloader="z" className="letters-loading">
                z
              </span>
              <span data-text-preloader="o" className="letters-loading">
                o
              </span>
              <span data-text-preloader="n" className="letters-loading">
                n
              </span>
              <span data-text-preloader="e" className="letters-loading">
                e
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Loader;
