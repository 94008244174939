import React from 'react'
// import logo from '../../../assets/images/logo.png'
// import logo from '../../../assets/images/TZORIG5.jpg'
// import logo from "../../../assets/images/tirezonelogo.png"
import logo from "../../../assets/images/tirezonelogo.webp"

function Logo(width?:any, height?:any) {
  return (
    <div className="logo"><a href="/"><img src={logo} width= {(width!==null && width!==undefined && width?.length>0)? width: "220px"} height={"45"} alt="logo"/></a></div>
  )
}

export default Logo
