import { useEffect, useState } from "react";
import { GetUserSalesOrders } from "../services/GetUserSalesOrders";
import { Order } from "../models/app";

export const useGetUserSalesOrders = (userId:string) => {
  const [res, setRes] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    const fetchOrders = async () => {
      await getOrders(userId);
    };

    fetchOrders();
  }, []);

  const getOrders = async (customerId: string) => {
    try {
      setLoading(true);

      const apiResponse = await GetUserSalesOrders(customerId);

      if (apiResponse?.Success === 0) {
        setError(apiResponse.Message);
      } else {
        setRes(apiResponse.Message);
        const ordersRes: Order[] = apiResponse?.Data?.value ?? [];
        // console.log(ordersRes);
        setOrders(ordersRes);
      }
    } catch (error: any) {
    setError("Error getting data");
} finally {
        setLoading(false);
    }
  };

  return { res, loading, error, orders};
};
