import React, {  useEffect, useState } from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Season } from "../../constants/enums";
import { AppState, Brand, CartItem, CatalogFlowSearchInput, Model, OptionalServices, Product, RawSize, Review, TireInfo } from "../../models/app";
import TireDetailReview from "../TireDetailReview";
import PageTitle from "../PageTitle";
import { Autocomplete, Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { addToCart } from "../../store/ducks/app";
import { useGetAdditionalServices } from "../../hooks/useGetAdditionalServices";
import Loader from "../UI/loader/Loader";
import OptionalServicesModal from "../modal/OptionalServicesModal";
import { RootState } from "../../store/ducks";
import { useGetRawSize } from "../../hooks/useGetRawSize";
import useCatalogFlow from "../../hooks/useCatalogFlow";
import AlertModal from "../UI/alert/Alert";

interface ModelsPageProps{
    model: Model,
    selectedBrand: string,
    searchInput: CatalogFlowSearchInput,
    setSearchInput: (value: CatalogFlowSearchInput) => void,
}

const ModelsCard: React.FC<ModelsPageProps> = ({model, selectedBrand, searchInput, setSearchInput}:ModelsPageProps) => {
  
    const [params] = useSearchParams();

    // const { getRawSizes, rawSizes, rawSizesLoading, rawSizesError } =  
    // useGetRawSize();

    const { HandleSearchClick, catalogError } = useCatalogFlow();

    const [open,setOpen] = useState(false);
    const [value, setValue] = useState<any>(null);

    const handleChange = (event: React.SyntheticEvent, newValue: RawSize | null) => {
        setValue(newValue);
        const newInput = {
            brand: selectedBrand??"",
            model: model.Code,
            RawSize: newValue ? newValue.RawSize : "",
          };
        // newInput.RawSize = newValue ? newValue.RawSize : '';
        setSearchInput(newInput);
    };

    useEffect(()=>{
        const selectedBrand = params?.get('selectedBrand');
        // getRawSizes(selectedBrand??"", model.Code);
        const newInput = {...searchInput};
        if(selectedBrand && selectedBrand?.length !==0){
            newInput.brand = selectedBrand;
        }
        newInput.model = model.Code;
        setSearchInput(newInput);
    },[params, model.Code, selectedBrand]);

  return (
<Grid container style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
            width: "100%",
        }}>
            <section className="shop-details p_relative" style={{
                width: "100%", 
                borderBottom: "2px solid #e5e5e5", 
                marginBottom: "20px",
                border: "1px solid darkgray",
                padding: "20px",
                maxWidth: "800px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
            }}>
                <div className="product-details-content p_relative" style={{
                    display: "flex", 
                    flexDirection: "column", 
                    justifyContent: "center", 
                    alignItems: "center", 
                    width: "100%"
                }}>
                    <Grid container sx={{
                        display: "flex", 
                        flexDirection: "column", 
                        justifyContent: "center", 
                        alignItems: "center", 
                        width: "100%", 
                        paddingBottom: "10px"
                    }}>
                        <Grid item xs={12} style={{
                            display: "flex", 
                            flexDirection: "column",
                            justifyContent: "center", 
                            alignItems: "center",
                            marginBottom: "20px"
                        }}>
                            { ((model.ImageSource) && (model.ImageSource.length>0))? <img src={model.ImageSource} alt="Brands's Logo" style={{ width: "180px", height: "auto", marginBottom: "5px" }} /> : <h3>{selectedBrand??""}</h3>}
                            <div className="image-box shop-details-left-image" style={{
                                display: "flex", 
                                justifyContent: "center"
                            }}>
                                <figure className="image" style={{
                                    display: "flex", 
                                    justifyContent: "center",
                                    width: "auto",
                                    height: "150px",
                                    overflow: "hidden",
                                }}>  
                                    <img src={model?.ModelImage?.length !== 0 ? model?.ModelImage : require("../../assets/images/Default-removebg-preview.png")} alt={model?.Code} style={{
                                        width: "auto",
                                        height: "150px", 
                                        objectFit: 'cover',
                                        overflow: "hidden",
                                    }} />
                                </figure>
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                                <div className="product-details p_relative d_block ml_20" style={{ display: "flex", flexDirection: "column", alignItems: "center",}}>
                                    {/* <h2 className="d_block fs_30 lh_40 fw_sbold font_family_inter">Brand: {selectedBrand ?? "No Name"}</h2> */}
                                    <div className="other-option">
                                        <ul className="list">
                                            <li className="p_relative d_block fs_18 font_family_poppins mb_5"><span className="fw_medium color_black">Brand: </span>{selectedBrand ?? "N/A"}</li>
                                            <li className="p_relative d_block fs_18 font_family_poppins mb_5"><span className="fw_medium color_black">Model: </span>{model?.Description ?? "N/A"}</li>
                                        </ul>
                                    </div>
                                    {catalogError && <AlertModal severity={"error"} text={catalogError} />}
                                    {/* <div className="addto-cart-box" style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-start", width: "100%", marginTop: "30px" }}> */}
                                    <Grid container spacing={2} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "15px"}}>
                                        <Grid item sx={{paddingLeft: "0 !important"}}>
                                            <Autocomplete
                                                autoSelect
                                                autoFocus
                                                autoHighlight
                                                openOnFocus
                                                value={value}
                                                onChange={handleChange}
                                                options={model.RawSizes ?? []}
                                                getOptionLabel={(option) => option.RawSize}
                                                isOptionEqualToValue={(option, value) => option.RawSize === value.RawSize}
                                                renderInput={(params) => (
                                                    <TextField 
                                                        {...params} 
                                                        label="Raw Size" 
                                                        variant="outlined"
                                                        sx={{
                                                            '& .MuiInputBase-root': {
                                                                height: '60px',
                                                                width: '150px',
                                                                borderRadius: '5px',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item sx={{paddingLeft: "0 !important"}}>
                                            <button 
                                                type="button" 
                                                className="theme-btn theme-btn-eight" 
                                                onClick={() => HandleSearchClick(searchInput, "", value ?? "")}
                                                style={{
                                                    marginLeft: "10px",
                                                    height: '60px',
                                                    padding: "10px",
                                                }}
                                            >
                                                Find Tires
                                            </button>
                                        </Grid>
                                    </Grid>
                                    {/* </div> */}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
        </Grid>
  )}

export default ModelsCard;
