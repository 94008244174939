// apiService.ts

import  { AxiosRequestConfig } from 'axios';
import axios from '../helpers/axiosInterceptor'
import { Endpoints } from '../constants/Endpoints';

export const getDiscounts = async () => {
  
  try {
    const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
    
    const response:any = await axios.get(Endpoints.GetDiscounts, config);
    
        if (response.data.Success === 0 && response.data.ErrorCode === 1) {
          console.error('Un expected error.');
          throw new Error('Un expected error');
        }
    
      return response.data;
    
    
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
