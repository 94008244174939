import React, { FC } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { GlobalStyles, SxProps, Theme } from "@mui/system";
import { FormControl } from "@mui/material";

interface DropDownProps {
  title: string;
  handleValueChange: (value: any | null) => void;
  data: string[];
  selectedValue: string;
  selectStyles?: SxProps<Theme>;
  listOpen: string;
  setListOpen: (value: string) => void;
  isLoading: boolean;
}

const DropDown: FC<DropDownProps> = ({
  title,
  handleValueChange,
  data,
  selectedValue,
  selectStyles,
  listOpen,
  setListOpen,
  isLoading,
}) => {
  const handleChange = (event: React.ChangeEvent<{}>, value: any | null) => {
    if (value !== null) {
      handleValueChange(value);
      setListOpen("");
    }
  };

  const backgroundColor = isLoading ? "#aaa" : "#fff";

  return (
    <div
      style={{
        width: "100%",
        marginBottom: "5px",
        borderRadius: "5px",
        padding: "10px",
      }}
    >
      <GlobalStyles
        styles={{
          // Targeting the dropdown options list inside Autocomplete globally
          ".MuiAutocomplete-listbox": {
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
            "&::-webkit-scrollbar": {
              width: "10px",
              borderRadius: "1px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#E7131A",
              borderRadius: "1px",
            },
          },
        }}
      />
      <FormControl fullWidth margin="none">
        <Autocomplete
          value={selectedValue}
          onChange={handleChange}
          options={data}
          getOptionLabel={(option) => option as string}
          disabled={data && data.length > 0 ? false : true}
          autoSelect
          autoFocus
          autoHighlight
          openOnFocus
          size="small"
          sx={{
            width: "100%",
            "& .MuiInputBase-root": {
              backgroundColor: "white",
              color: "black",
              fontWeight: "bold",
              padding: "10px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "black",
              borderRadius: "4px",
            },
            "& .MuiAutocomplete-popupIndicator": {
              color: "inherit",
            },
            ".css-1nazdr-MuiInputBase-root-MuiOutlinedInput-root": {
              paddingRight: "65px",
              borderRadius: "5px",
              background: `${backgroundColor}`,
            },
            "& .MuiAutocomplete-clearIndicator svg": {
              display: "none",
              color: "black",
            },
            "&.MuiInputLabel-shrink": {
              color: "black",
            },
            ...selectStyles,
          }}
          renderInput={(params) => (
            <TextField
              sx={{
                "& .MuiFormLabel-root": {
                  color: "black",
                },
                "& .MuiInputLabel-root.MuiInputLabel-shrink": {
                  // color: 'white !important',
                },
              }}
              {...params}
              inputProps={{
                ...params.inputProps,
                "aria-required": true,
              }}
              label={
                title === "Width"
                  ? "Section Width"
                  : title === "Profile"
                  ? "Aspect Ratio"
                  : title === "Wheel Size"
                  ? "Wheel Diameter"
                  : title
              }
            />
          )}
        />
      </FormControl>
    </div>
  );
};

export default DropDown;
